import React from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useMaterialUIController } from 'context'

const Support = () => {
    const [controller] = useMaterialUIController();
    const { profile } = controller;
    const email = profile.email;

    return (
        <DashboardLayout>
            <DashboardNavbar title="Support" />

            <MDBox style={{ background: "#fff", padding: "24px", borderRadius: "24px", boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%)" }} >
                <MDTypography marginBottom={2} >MaxU | Support</MDTypography>
                <MDTypography fontSize={17} marginBottom={2} >Have a question? Need some help?</MDTypography>
                <MDTypography fontSize={17} marginBottom={2} >
                    <a
                        style={{ color: "#10b0d5" }}
                        href={`mailto:support@maxu.co?subject=MaxU Support Request from ${email}&body=Please replace this message with a description of your concern.`}>
                        Click here or email us at support@maxu.co
                    </a> to send us a support request!
                </MDTypography>
                <MDTypography fontSize={17} marginBottom={2} >Make sure to include all relevant information in your request, including screenshots of any errors, where applicable.</MDTypography>
                <MDTypography fontSize={17} marginBottom={2} >Thanks!</MDTypography>
                <MDTypography style={{ fontSize: "18px", textAlign: "right" }} >- The MaxU Team</MDTypography>
            </MDBox>
        </DashboardLayout>
    )
}

export default Support