import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import RequireAuth from "pages/authentication/RequiredAuth";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Otis Admin PRO React example components
// import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Otis Admin PRO React themes
import theme from "assets/theme";

// Otis Admin PRO React Dark Mode themes
// import themeDark from "assets/theme-dark";

// Otis Admin PRO React routes
import {
  allRoutes,
  routes,
  athleteRoutes,
  adultAthleteRoutes,
  adminRoutes,
  contentManagerRoutes,
} from "routes";
import { Assessment } from "pages/assessments";
import { Insight } from "pages/insights";
import { Auth } from "pages/authentication";
import { PrivacyPolicy } from "pages/conditions/PrivacyPolicy";
import { TermsAndConditions } from "pages/conditions/TermsANDConditions";
import { PaymentTerms } from "pages/conditions/PaymentTerms";
import PlayContent from "pages/playList/PlayContent";
// Otis Admin PRO React contexts
import { useMaterialUIController } from "context";

export default function App() {
  const [controller] = useMaterialUIController();
  const { persona, beta } = controller;
  const { pathname } = useLocation();
  const { profile } = controller;

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (window.pendo) {
      window.pendo.initialize({
        apiKey: process.env.REACT_APP_PENDO_API_KEY,
      });
      if (profile.id) {
        window.pendo.identify({
          visitor: {
            id: profile.id,
            email: profile.email,
            name: profile.details.name,
          },
        });
      }
    }
  }, [profile]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        let beta_route = route.beta_route ? true : false;
        if (!beta_route || beta) {
          return (
            <Route
              exact
              path={route.route}
              element={<RequireAuth>{route.component}</RequireAuth>}
              key={route.key}
            />
          );
        }
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/* {persona === "athlete" ? getRoutes(athleteRoutes) : getRoutes(routes)} */}
        {(() => {
          switch (persona) {
            case "defaultPersona":
              return getRoutes(allRoutes);
              break;
            case "athlete":
              return getRoutes(athleteRoutes);
              break;
            case "guardian":
              return getRoutes(routes);
              break;
            case "adultAthlete":
              return getRoutes(adultAthleteRoutes);
              break;
            case "admin":
              return getRoutes(adminRoutes);
              break;
            case "contentManager":
              return getRoutes(contentManagerRoutes);
              break;
            default:
              return getRoutes(routes);
          }
        })()}
        <Route path="assessment">
          <Route
            path=":id"
            element={
              <RequireAuth>
                <Assessment />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="insight">
          <Route
            path=":id/:user_id"
            element={
              <RequireAuth>
                <Insight />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route
          path="/watch/:contentTitle"
          element={
            <RequireAuth>
              {" "}
              <PlayContent />{" "}
            </RequireAuth>
          }
        />
        <Route path="/terms_conditions" element={<TermsAndConditions />} />
        <Route path="/payment_terms" element={<PaymentTerms />} />
        <Route exact path="auth/:initialState" element={<Auth />} />
        <Route path="*" element={<Auth />} />
      </Routes>
    </ThemeProvider>
  );
}
