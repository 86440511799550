import React, { useCallback, useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";

import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import { CircularProgress, FormLabel, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { call } from "services/axiosService/core";
import { UsersTable } from "./UsersTable";
import { AssessmentsTable } from "./AssessmetsTable";
import { FiltersTable } from "./FiltersTable";
import { ReferralsTable } from "./ReferralTable";
import { VendorsTable } from "./VendorTable";
import InputAdornment from "@mui/material/InputAdornment";
import Refresh from "pages/refresh/Refresh";

import { ReactMultiEmail, isEmail } from "react-multi-email";
import * as Sentry from "@sentry/browser";
import "react-multi-email/style.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function AdminDashboard() {
  const [controller] = useMaterialUIController();
  const { profile } = controller;

  const [tableType, setTableType] = useState("Users");
  const [users, setUsers] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [filters, setFilters] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingAssessments, setLoadingAssessments] = useState(false);
  const [openMassInviteModal, setOpenMassInviteModal] = useState(false);
  const [openActivityReportModal, setOpenActivityReportModal] = useState(false);
  const [openAddReferralModal, setOpenAddReferralModal] = useState(false);
  const [openAddVendorModal, setOpenAddVendorModal] = useState(false);
  // const [openRegenerateInsightsModal, setOpenRegenerateInsightsModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const closeMassInviteModalHandler = () => {
    setOpenMassInviteModal(false);
  };

  const closeActivityReportModalHandler = () => {
    setOpenActivityReportModal(false);
  };

  const closeAddReferralModalHandler = () => {
    setOpenAddReferralModal(false);
  };

  const closeAddVendorModalHandler = () => {
    setOpenAddVendorModal(false);
  };

  // const closeRegenerateInsightModalHandler = () => {
  //   setOpenRegenerateInsightsModal(false);
  // };

  useEffect(() => {
    loadAssessments();
  }, []);

  useEffect(() => {
    switch (tableType) {
      case "Users":
        loadUsers();
        break;
      case "Assessments":
        loadAssessments();
        break;
      case "Filters":
        loadFilters();
        break;
      case "Referrals":
        loadReferrals();
        break;
      case "Vendors":
        loadVendors();
        break;
      default:
        loadUsers();
        break;
    }
  }, [tableType]);

  const loadUsers = () => {
    setLoadingUsers(true);
    call(`users`, "GET", {})
      .then((res) => {
        if (res.status == 404) {
          setUsers([]);
          setLoadingUsers(false);
        } else if (res.status == 200) {
          setUsers(res.data.Items);
          setLoadingUsers(false);
        } else if (res.status == 440) {
          setLoadingUsers(false);
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        Sentry.captureException(err);
      });
  };

  const loadAssessments = () => {
    if (profile.id) {
      setLoadingAssessments(true);
      call(`users/${profile.id}/assessments`, "GET", {})
        .then((res) => {
          if (res.status == 404) {
            setAssessments([]);
            setLoadingAssessments(false);
          } else if (res.status == 200) {
            setAssessments(res.data.Assessments);
            setLoadingAssessments(false);
          } else if (res.status == 440) {
            setSessionExpired(true);
            setLoadingAssessments(false);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          Sentry.captureException(err);
        });
    }
  };

  const loadFilters = () => {
    call(`filters/${profile.id}`, "GET", {})
      .then((res) => {
        if (res.status == 404) {
          setFilters([]);
        } else if (res.status == 200) {
          setFilters(res.data.Items);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        Sentry.captureException(err);
      });
  };

  const loadReferrals = () => {
    call(`referrals`, "GET", {})
      .then((res) => {
        if (res.status == 404) {
          setReferrals([]);
        } else if (res.status == 200) {
          setReferrals(res.data.Items);
        } else if (res.status == 440) {
          setSessionExpired(true);
          setReferrals([]);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        Sentry.captureException(err);
      });
  };

  const loadVendors = () => {
    call(`vendors`, "GET", {})
      .then((res) => {
        if (res.status == 404) {
          setVendors([]);
        } else if (res.status == 200) {
          setVendors(res.data.Items);
        } else if (res.status == 440) {
          setSessionExpired(true);
          setVendors([]);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        Sentry.captureException(err);
      });
  };

  const importContentHandler = () => {
    setInProgress(true);
    call(`content/import`, "POST", {
      body: {},
    })
      .then((response) => {
        if (response.status == 200) {
          setAlertMessage("User/Content Metrics created");
          setInProgress(false);
          setOpenSnackbar(true);
        } else if (response.status == 440) {
          setSessionExpired(true);
        } else {
          setAlertMessage("Error while creating User/Content Metrics");
          setOpenSnackbar(true);
          setInProgress(false);
        }
      })
      .catch(function (err) {
        Sentry.captureException(err);
        setAlertMessage("Error while creating User/Content Metrics");
        setOpenSnackbar(true);
        setInProgress(false);
        console.log(err);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={`Admin Dashboard`} />
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="people"
                title="Total Users"
                // count={tableData.rows.length}
                count={loadingUsers ? <CircularProgress size={15} color="primary" /> : users.length}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="psychology"
                title="Total Assessments"
                count={
                  loadingAssessments ? (
                    <CircularProgress size={15} color="primary" />
                  ) : (
                    assessments.length
                  )
                }
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" m={2}>
            <Autocomplete
              disableClearable
              value={tableType}
              options={["Users", "Assessments", "Filters", "Referrals", "Vendors"]}
              onChange={(event, newValue) => {
                setTableType(newValue);
              }}
              size="medium"
              sx={{ width: 300, marginRight: 5 }}
              renderInput={(params) => <MDInput {...params} label="Select Option" />}
            />
            {tableType == "Referrals" && (
              <MDButton
                onClick={() => setOpenAddReferralModal(true)}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Add Referral
              </MDButton>
            )}

            {tableType == "Vendors" && (
              <MDButton
                onClick={() => setOpenAddVendorModal(true)}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Add Vendor
              </MDButton>
            )}

            {/* {tableType == "Assessments" && (
              <MDButton
                onClick={() => setOpenRegenerateInsightsModal(true)}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Regenerate Insights
              </MDButton>
            )} */}
            {tableType == "Users" && (
              <MDButton
                onClick={() => setOpenMassInviteModal(true)}
                sx={{ width: 300 }}
                variant="contained"
                color="primary"
              >
                Mass Invite
              </MDButton>
            )}

            {/* <MDButton
              onClick={importContentHandler}
              sx={{ width: 300 }}
              disabled={inProgress}
              variant="contained"
              color="primary"
            >
              {inProgress ? <CircularProgress size={25} color="white" /> : "CreateMetrics"}
            </MDButton> */}

            <MDButton
              onClick={() => setOpenActivityReportModal(true)}
              sx={{ width: 300 }}
              disabled={inProgress}
              variant="contained"
              color="primary"
            >
              Activity Report
            </MDButton>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {tableType}
            </MDTypography>
            {tableType == "Users" && (
              <UsersTable
                users={users ?? []}
                loadUsers={loadUsers}
                loadAssessments={loadAssessments}
                loadFilters={loadFilters}
              />
            )}
            {tableType == "Assessments" && (
              <AssessmentsTable assessments={assessments ?? []} loadAssessments={loadAssessments} />
            )}
            {tableType == "Filters" && (
              <FiltersTable filters={filters ?? []} loadFilters={loadFilters} />
            )}
            {tableType == "Referrals" && (
              <ReferralsTable referrals={referrals ?? []} loadReferrals={loadReferrals} />
            )}

            {tableType == "Vendors" && (
              <VendorsTable vendors={vendors ?? []} loadVendors={loadVendors} />
            )}

            {!!openMassInviteModal && (
              <MassInviteModal
                openMassInviteModal={openMassInviteModal}
                closeMassInviteModalHandler={closeMassInviteModalHandler}
                profile={profile}
                setOpenSnackbar={setOpenSnackbar}
                setAlertMessage={setAlertMessage}
                setSessionExpired={setSessionExpired}
              />
            )}

            {!!openAddReferralModal && (
              <AddReferralModal
                openAddReferralModal={openAddReferralModal}
                closeAddReferralModalHandler={closeAddReferralModalHandler}
                profile={profile}
                setOpenSnackbar={setOpenSnackbar}
                setAlertMessage={setAlertMessage}
                loadReferrals={loadReferrals}
                setSessionExpired={setSessionExpired}
              />
            )}

            {!!openAddVendorModal && (
              <AddVendorModal
                openAddVendorModal={openAddVendorModal}
                closeAddVendorModalHandler={closeAddVendorModalHandler}
                profile={profile}
                setOpenSnackbar={setOpenSnackbar}
                setAlertMessage={setAlertMessage}
                loadVendors={loadVendors}
                setSessionExpired={setSessionExpired}
              />
            )}

            {!!openActivityReportModal && (
              <ActivityReportModal
                openActivityReportModal={openActivityReportModal}
                closeActivityReportModalHandler={closeActivityReportModalHandler}
                profile={profile}
                setOpenSnackbar={setOpenSnackbar}
                setAlertMessage={setAlertMessage}
                setSessionExpired={setSessionExpired}
              />
            )}

            {/* {!!openRegenerateInsightsModal && (
              <RegenerateinsightsModal
                openRegenerateInsightsModal={openRegenerateInsightsModal}
                closeRegenerateInsightModalHandler={closeRegenerateInsightModalHandler}
                profile={profile}
                setOpenSnackbar={setOpenSnackbar}
                setAlertMessage={setAlertMessage}
                loadAssessments={loadAssessments}
              />
            )} */}

            <Snackbar
              autoHideDuration={2000}
              open={openSnackbar}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
                {alertMessage}
              </Alert>
            </Snackbar>
          </MDBox>
        </Card>
      </MDBox>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
}

const MassInviteModal = ({
  openMassInviteModal,
  closeMassInviteModalHandler,
  profile,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired,
}) => {
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState([]);

  const styles = {
    fontFamily: "sans-serif",
    width: "500px",
    border: "1px solid #eee",
    background: "#f3f3f3",
    padding: "25px",
    margin: "20px",
  };

  const SendEmailHandler = () => {
    call(`users/${profile.id}/emails`, "POST", {
      body: { email_type: "mass_invite", coach_emails: emails },
    })
      .then((response) => {
        if (response.status == 200) {
          setAlertMessage("Invite Sent Successfully");
          closeMassInviteModalHandler();
          setOpenSnackbar(true);
        } else if (response.status == 440) {
          setSessionExpired(true);
          closeMassInviteModalHandler();
        } else {
          setAlertMessage("Error while sending the invite");
          closeMassInviteModalHandler();
          setOpenSnackbar(true);
        }
      })
      .catch(function (err) {
        setAlertMessage("Error while sending the invite");
        Sentry.captureException(err);
        closeMassInviteModalHandler();
        setOpenSnackbar(true);
        console.log(err);
      });
  };

  return (
    <Dialog open={openMassInviteModal} onClose={closeMassInviteModalHandler}>
      <DialogTitle>Mass Invite - input up to 25 email addresses to send MaxU invites.</DialogTitle>
      <DialogContent>
        <MDBox p={1} lineHeight={1}>
          <div style={styles}>
            <ReactMultiEmail
              placeholder="Enter Email Address Here"
              emails={emails}
              onChange={(_emails: string[]) => {
                setEmails(_emails);
              }}
              style={{ fontSize: "20px" }}
              autoFocus={true}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDButton
          disabled={emails.length <= 0}
          onClick={SendEmailHandler}
          style={{ color: "white", background: "#0AB2D6" }}
        >
          Invite
        </MDButton>
        <MDButton
          onClick={closeMassInviteModalHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

const AddReferralModal = ({
  openAddReferralModal,
  closeAddReferralModalHandler,
  profile,
  loadReferrals,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired,
}) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("maxu_yearly");
  const [targetPrice, setTargetPrice] = useState(99);
  const [usageCount, setUsageCount] = useState(1000);
  const [showError, setShowError] = useState(false);

  const addReferralHandler = (e) => {
    e.preventDefault();
    const data = {};
    data["name"] = name;
    data["target_price"] = targetPrice;
    data["remaining_usage_count"] = usageCount;
    data["referral_type"] = type;
    call(`referrals`, "POST", { body: data })
      .then((res) => {
        if (res.status == 200) {
          closeAddReferralModalHandler();
          loadReferrals();
          setOpenSnackbar(true);
          setAlertMessage("Added successfully");
        } else if (res.status == 440) {
          setSessionExpired(true);
          closeAddReferralModalHandler();
        } else {
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        closeAddReferralModalHandler();
      });
  };
  return (
    <Dialog fullWidth={true} open={openAddReferralModal} onClose={closeAddReferralModalHandler}>
      <DialogTitle>Add New Referral code</DialogTitle>
      <form onSubmit={addReferralHandler}>
        <DialogContent>
          <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
            Referral Name
          </FormLabel>
          <TextField
            name="name"
            type="text"
            size="medium"
            sx={{ height: 50 }}
            inputProps={{ maxLength: 32 }}
            value={name}
            error={showError && name.length <= 0}
            fullWidth
            onChange={(e) => {
              if (e.nativeEvent.data != " ") {
                setName(e.target.value.trim());
              }
            }}
          />

          <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
            Target Price
          </FormLabel>
          <TextField
            name="targetPrice"
            type="number"
            size="medium"
            sx={{ height: 50 }}
            inputProps={{ max: 480, min: 0 }}
            value={targetPrice}
            error={showError && (targetPrice < 0 || targetPrice > 480)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography fontSize={15}>$</MDTypography>
                </InputAdornment>
              ),
            }}
            fullWidth
            onChange={(e) => {
              setTargetPrice(e.target.value);
            }}
          />
          <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
            UsageCount
          </FormLabel>
          <TextField
            name="usageCount"
            type="number"
            size="medium"
            sx={{ height: 50 }}
            inputProps={{ max: 9999, min: 0 }}
            value={usageCount}
            error={showError && (usageCount <= 0 || usageCount > 9999)}
            fullWidth
            onChange={(e) => {
              setUsageCount(e.target.value);
            }}
          />
          <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
            ReferralType
          </FormLabel>
          <Autocomplete
            disableClearable={true}
            limitTags={1}
            multiple={false}
            value={type}
            options={["maxu_yearly", "true_mindsets_yearly", "true_mindsets_monthly"]}
            onChange={(event, newValue) => {
              setType(newValue);
            }}
            size="medium"
            sx={{ Maxwidth: 500, marginRight: 5 }}
            renderInput={(params) => <TextField {...params} />}
          />
        </DialogContent>

        <DialogActions>
          <MDButton
            type="submit"
            onClick={() => {
              setShowError(true);
            }}
            style={{ color: "white", background: "#0AB2D6" }}
          >
            Add
          </MDButton>
          <MDButton
            onClick={closeAddReferralModalHandler}
            style={{ color: "white", background: "#ED5F72" }}
          >
            Close
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ActivityReportModal = ({
  openActivityReportModal,
  closeActivityReportModalHandler,
  profile,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired,
}) => {
  const [report, setReport] = useState("All incomplete assessments");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxStartDate, setMaxStartate] = useState("");
  const [maxEndDate, setMaxEndDate] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    let today = new Date();
    let end_date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getSeconds()
    );
    setEndDate(end_date.toISOString().slice(0, 10));

    let max_start_date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1,
      today.getHours(),
      today.getMinutes(),
      today.getSeconds()
    );
    setMaxStartate(max_start_date.toISOString().slice(0, 10));
    setMaxEndDate(end_date.toISOString().slice(0, 10));
  }, []);

  const requestReportHandler = (e) => {
    e.preventDefault();
    if (new Date(startDate) >= new Date(endDate)) {
      setError("Start date must be less than end date.");
      return;
    }
    let url = "";
    let params = {};
    if (report == "All incomplete assessments") {
      url = `users/${profile.id}/activityreport/incompleteassessments`;
    } else if (report == "All assessments") {
      url = `users/${profile.id}/activityreport/allassessments`;
    } else if (report == "Incomplete assessments in date range") {
      url = `users/${profile.id}/activityreport/incompleteassessments`;
      params = { start_date: startDate, end_date: endDate };
    } else if (report == "Assessments in date range") {
      url = `users/${profile.id}/activityreport/allassessments`;
      params = { start_date: startDate, end_date: endDate };
    } else if (report == "Paid and pending accounts") {
      url = `users/${profile.id}/activityreport/paidandpending`;
    } else if (report == "Partners signup activity") {
      url = `users/${profile.id}/activityreport/partnersactivity`;
    } 
    call(url, "POST", {
      params: params,
      headers: { "Content-Type": "application/json" },
      body: {},
    }).then((res) => {
      if (res.status == 200) {
        setAlertMessage(
          "Request added successfuuly. You will receive the email in couple of minutes"
        );
        setOpenSnackbar(true);
        closeActivityReportModalHandler();
      } else if (res.status == 440) {
        closeActivityReportModalHandler();
        setSessionExpired(true);
      } else {
        setAlertMessage("Error while adding the request.");
        setOpenSnackbar(true);
      }
    });
  };

  return (
    <Dialog
      fullWidth={true}
      open={openActivityReportModal}
      onClose={closeActivityReportModalHandler}
    >
      <DialogTitle>Activity Report</DialogTitle>
      <form onSubmit={requestReportHandler}>
        <DialogContent>
          <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
            ReportType
          </FormLabel>
          <Autocomplete
            disableClearable={true}
            limitTags={1}
            multiple={false}
            value={report}
            options={[
              "All incomplete assessments",
              "Incomplete assessments in date range",
              "All assessments",
              "Assessments in date range",
              "Paid and pending accounts",
              "Partners signup activity",
            ]}
            onChange={(event, newValue) => {
              setReport(newValue);
            }}
            size="medium"
            sx={{ Maxwidth: 500, marginRight: 5 }}
            renderInput={(params) => <TextField {...params} />}
          />
          {(report == "Incomplete assessments in date range" ||
            report == "Assessments in date range") && (
            <>
              <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
                startDate
              </FormLabel>
              <TextField
                type="date"
                name="From"
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                inputProps={{ max: maxStartDate }}
                helperText={error}
                error={error !== ""}
                required
                fullWidth
                style={{ display: "block" }}
              />
              <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
                EndDate
              </FormLabel>
              <TextField
                type="date"
                name="to"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                inputProps={{ max: maxEndDate }}
                helperText={error}
                error={error !== ""}
                required
                fullWidth
                style={{ display: "block" }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton type="submit" style={{ color: "white", background: "#0AB2D6" }}>
            Send Request
          </MDButton>
          <MDButton
            onClick={closeActivityReportModalHandler}
            style={{ color: "white", background: "#ED5F72" }}
          >
            Close
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const AddVendorModal = ({
  openAddVendorModal,
  closeAddVendorModalHandler,
  profile,
  loadVendors,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [showError, setShowError] = useState(false);

  const addVendorHandler = (e) => {
    e.preventDefault();
    let data = {};
    data["name"] = name;
    data["email"] = email;
    data["permissions"] = permissions;
    call(`vendors`, "POST", { body: data })
      .then((res) => {
        if (res.status == 200) {
          closeAddVendorModalHandler();
          loadVendors();
          setOpenSnackbar(true);
          setAlertMessage("Vendor added successfully");
        } else if (res.status == 440) {
          setSessionExpired(true);
          closeAddVendorModalHandler();
        } else {
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        closeAddVendorModalHandler();
      });
  };

  return (
    <Dialog fullWidth={true} open={openAddVendorModal} onClose={closeAddVendorModalHandler}>
      <DialogTitle>Add New Vendor</DialogTitle>
      <form onSubmit={addVendorHandler}>
        <DialogContent>
          <FormLabel
            sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px", fontWeight: "bold" }}
          >
            Vendor Name
          </FormLabel>
          <TextField
            name="name"
            placeholder="vendor Name"
            type="text"
            size="medium"
            sx={{ height: 50 }}
            inputProps={{ maxLength: 32 }}
            value={name}
            error={showError && name.length <= 0}
            fullWidth
            onChange={(e) => {
              if (e.nativeEvent.data != " ") {
                setName(e.target.value.trim());
              }
            }}
          />
          <FormLabel
            sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px", fontWeight: "bold" }}
          >
            Email
          </FormLabel>
          <TextField
            placeholder="xyz@email.com"
            name="email"
            type="email"
            size="medium"
            sx={{ height: 50 }}
            inputProps={{ maxLength: 100 }}
            value={email}
            error={showError && email.length <= 0}
            fullWidth
            onChange={(e) => {
              if (e.nativeEvent.data != " ") {
                setEmail(e.target.value.trim());
              }
            }}
          />
          <FormLabel
            sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px", fontWeight: "bold" }}
          >
            Provide Access to
          </FormLabel>
          <Autocomplete
            disableClearable={true}
            limitTags={2}
            multiple={true}
            value={permissions}
            options={["AddReferral"]}
            onChange={(event, newValue) => {
              setPermissions(newValue);
            }}
            size="medium"
            sx={{ Maxwidth: 500, marginRight: 5 }}
            renderInput={(params) => <TextField {...params} label="Functions" />}
          />
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            disabled={name.length <= 0 || email.length <= 0 || permissions.length <= 0}
            style={{ color: "white", background: "#0AB2D6" }}
          >
            Add
          </MDButton>
          <MDButton
            onClick={closeAddVendorModalHandler}
            style={{ color: "white", background: "#ED5F72" }}
          >
            Close
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

// const RegenerateinsightsModal = ({
//   openRegenerateInsightsModal,
//   closeRegenerateInsightModalHandler,
//   profile,
//   setOpenSnackbar,
//   setAlertMessage,
//   loadAssessments,
// }) => {
//   const regenerateInsights = () => {
//     console.log("Regenerate ALL Insights ");
//     call(`users/${profile.id}/insights`, "PUT", { body: {} })
//       .then((res) => {
//         if (res.status == 200) {
//           closeRegenerateInsightModalHandler();
//           loadAssessments();
//           setOpenSnackbar(true);
//           setAlertMessage("Insights regenerated successfully");
//         } else {
//           setOpenSnackbar(true);
//           setAlertMessage("Error while regenerating insights ");
//         }
//       })
//       .catch((err) => {
//         closeRegenerateInsightModalHandler();
//         setOpenSnackbar(true);
//         setAlertMessage("Error while regenerating insights");
//       });
//   };

//   return (
//     <Dialog
//       open={openRegenerateInsightsModal}
//       onClose={closeRegenerateInsightModalHandler}
//       maxWidth={"md"}
//     >
//       <DialogTitle> Regenerate All Insights </DialogTitle>
//       <DialogContent>Are you sure you want to regenerate insights ?</DialogContent>

//       <DialogActions>
//         <Button onClick={regenerateInsights} style={{ color: "white", background: "#0AB2D6" }}>
//           Regenrate
//         </Button>
//         <Button
//           onClick={closeRegenerateInsightModalHandler}
//           style={{ color: "white", background: "#ED5F72" }}
//         >
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };
