const bestPracticesData = [
  {
    persona: "For Athletes",
    description: null,
    bestPractice: [
        "1) Take your assessment immediately – the assessment should take approximately 3-5 minutes and delaying won’t help anything",
        "2) After completing the assessment, review your results including the main insights as well as the additional details report",
        "3) The additional details report will give further insights related to specific personality traits, so it is helpful to understand what they mean:",
        `a. Agreeableness - Agreeableness describes how you tend to behave in relationships with others, how you treat them and if you are compassionate and trusting or aloof and suspicious`,
        "b. Openness - Openness is characterized by an individual's receptiveness to new ideas, experiences, and intellectual curiosity.",
        "c. Extraversion - Extraversion assesses an individual's level of sociability, assertiveness, and preference for being in the company of others",
        "d. Conscientiousness - Conscientiousness reflects an individual's level of organization, responsibility, and self-discipline.",
        "e. Temperament - Temperament reflects an individual's emotional stability, anxiety levels, and tendency to experience negative emotions.",
        "4) Share your results openly with your parents and coaches. Having an open conversation about your results can help them support your growth as an athlete and as an individual.",
        "5) Explore the content library and discover pieces that resonate with you. Don't forget to rate the content – this will help tailor your experience with new and relevant material.",
        "6) Make it a habit to check back weekly as we consistently add fresh content to the MaxU platform. Your journey of improvement is an ongoing process, and we're here to support you every step of the way."

    ]
},
{
    persona: "For Parents",
    description: "Thank you for investing in your child's growth and development through MaxU. Together, we're fostering a holistic approach to success.",
    bestPractice: [
        "1) Engage in a conversation with your child about why you're choosing to invest in MaxU – an investment that extends to enhancing their performance both on and off the field.",
        "2) If you invite your child to take the assessment, you'll receive a notification once it's completed. In case the assessment isn't completed within the first week, kindly remind your child to do so. During the assessment, encourage them not to overthink – trust their initial instincts for each question.",
        "3) After the assessment is finished, take the time to review the results together and have a discussion about them.",
        "4) Dive into the detailed report on specific personality traits.  It is helpful to understand what they mean:",
        `a. Agreeableness -Agreeableness describes how you tend to behave in relationships with others, how you treat them and if you are compassionate and trusting or aloof and suspicious`,
        "b. Openness - Openness is characterized by an individual's receptiveness to new ideas, experiences, and intellectual curiosity.",
        "c. Extraversion - Extraversion assesses an individual's level of sociability, assertiveness, and preference for being in the company of others",
        "d. Conscientiousness - Conscientiousness reflects an individual's level of organization, responsibility, and self-discipline.",
        "e. Temperament - Temperament reflects an individual's emotional stability, anxiety levels, and tendency to experience negative emotions.",
        "5) Navigate through the content library and suggest resources you believe would be beneficial for your child. When you do, your child will be alerted and can save the content in a dedicated folder.",
        "6) Consider inviting coaches and other influential figures in your child's life to view the results. Rest assured, this process is secure, and you retain the ability to revoke access whenever you wish. By inviting coaches, they can gain insights tailored to your child, enabling them to provide more effective guidance. It's worth noting that coaches who focus on nurturing life skills alongside physical abilities tend to achieve an average win rate that's 10% higher."
    ]
},
{
    persona: "For Coaches",
    description:"Thank you for your commitment to nurturing athletes' growth and potential through MaxU. Together, we're shaping a comprehensive path to excellence.",
    bestPractice: [
        "1) When accepting an invitation, kindly use the same email address as provided in the invitation.",
        "2) Within your dashboard, you have the option to establish filters for a team-specific view, especially useful if you're coaching multiple teams. This feature ensures swift access to specific athletes' information",
        "3) If you signed up directly, encourage parents of athletes on your team to join and to invite the athletes.  This will provide max benefit to you and your team.  Remember, coaches that offer life skills along with athletic skills have a 10% higher win rate.",
        "4) Explore the concise high level insights that will give the athlete 3 specific tips.  These will be helpful to offer you a quick grasp of each athlete's profile.",
        "5) Recommend relevant content to individual athletes, motivating them to engage in further reading and learning. This not only aids their on-field performance but also contributes to their holistic development.",
        "6) You'll find specialized content for coaches, addressing strategies for coaching specific athletes and navigating various scenarios. Utilize this resource to enhance your understanding and refine on-field performance.",
        "7) Remember, coaches who integrate life skills into their coaching approach tend to enjoy an average win percentage that's 10% higher, as per The Aspen Institute. This underscores the pivotal role of holistic coaching in achieving success."
    ]
}
]
   

export default bestPracticesData;
