import React from "react";
import PaymentTermsPDF from "../../assets/pdf/Payment_Terms.pdf"

export function PaymentTerms() {
  return (
    <div >
      <style jsx global>{`
        body {
          margin: 0px;
          padding: 0px;
        }
      `}</style>
      <iframe
        src={PaymentTermsPDF}
        style={{
          display: "block",
          position: "absolute",
          width: "100%",
          height: "100%",
          border: "none",
          margin: "0px",
        }}
      />
    </div>
  );
}