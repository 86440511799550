// SubscriptionModal.js
import React, { useState } from 'react';
import './SubscriptionModal.css'; // Import your custom styles for the modal

const SubscriptionModal = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState('true_mindsets_yearly');

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleSubscribe = () => {
    // You can perform subscription logic here
    const price = selectedOption === 'true_mindsets_monthly' ? 45 : 480;
    onClose(selectedOption);
  };

  return (
    <div className={`subscription-modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <h2>Select a Subscription Plan</h2>
        <div className="subscription-options">
        <div className={`option ${selectedOption === 'true_mindsets_yearly' ? 'selected' : ''}`} onClick={()=> {handleOptionChange('true_mindsets_yearly')}}>
            <label className="save">Save $60</label>
            <label>YEARLY</label>
            <span>$480</span>
            <label>PER YEAR</label>
          </div>
          <div className={`option ${selectedOption === 'true_mindsets_monthly' ? 'selected' : ''}`} onClick={()=> {handleOptionChange('true_mindsets_monthly')}}>
            <label className={`month ${selectedOption === 'true_mindsets_monthly' ? 'selected' : ''}`}>MONTHLY</label>
            <span>$45</span>
            <label>PER MONTH</label>
          </div>
        </div>
        <button onClick={handleSubscribe}>Next</button>
      </div>
    </div>
  );
};

export default SubscriptionModal;
