import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import * as Sentry from "@sentry/react";
import App from "App";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  securityToken: process.env.REACT_APP_SENTRY_TOKEN ,
  environment: process.env.NODE_ENV,
  integrations: [
    // Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Authenticator.Provider>
      <Sentry.ErrorBoundary >
        <App />
       </Sentry.ErrorBoundary>
      </Authenticator.Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
