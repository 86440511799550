import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Loader from "pages/loader/Loader";
import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ReactPlayer from "react-player";
import MaxuLogo from "assets/images/nav-logo.png";
import { useMaterialUIController } from "context";
import { call } from "services/axiosService/core";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";

const PlayList = () => {
  const [controller] = useMaterialUIController();
  const { profile, persona } = controller;
  const [playList, setPlayList] = useState([]);
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState({});
  const [playContent, setPlayContent] = useState(false);
  const [userMetricsData, setUserMetricsData] = useState(null);
  const [likedContent, setLikedContent] = useState([]);
  const [dislikedContent, setDislikedContent] = useState([]);
  const [savedContent, setSavedContent] = useState([]);
  const [viewedContent, setViewedContent] = useState([]);
  const [recommendedContent, setRecommendedContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noContent, setNoContent] = useState(false);
  const [contentFilter, setContentFilter] = useState("all");
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    loadUserContentMetrics();
  }, [profile]);

  useEffect(() => {
    getUserPlayList();
  }, []);

  const loadUserContentMetrics = () => {
    call(`users/${profile.id}/contentmetrics`, "GET", {})
      .then((res) => {
        // console.log("res ", res);
        if (res.status == 200) {
          let data = res.data;
          setUserMetricsData(data);
          setLikedContent([...data.likedcontent]);
          setDislikedContent([...data.dislikedcontent]);
          setViewedContent([...data.viewedcontent]);
          setSavedContent([...data.savedcontent]);
          setRecommendedContent([...data.recommendedcontent]);
        } else if (res.status == 440) {
          console.log("expired");
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const getUserPlayList = () => {
    setLoading(true);
    call(`users/${profile.id}/playlist`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          if (res.data.length == 0) {
            setNoContent(true);
          }
          setContents([...res.data]);
          setPlayList([...res.data]);
          setLoading(false);
        } else if (res.status == 440) {
          setLoading(false);
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNoContent(true);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const likeHandler = (title) => {
    let eventkey = { event: "likecontent", value: title };
    var currentlikedContent = [...likedContent];
    var currentdislikedContent = [...dislikedContent];
    currentlikedContent.push(title);
    // if content in disliked array remove it from there
    if (currentdislikedContent.includes(title)) {
      const index = currentdislikedContent.indexOf(title);
      currentdislikedContent.splice(index, 1);
      eventkey = { event: "likecontent-removedislike", value: title };
    }
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;

    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setLikedContent([...currentlikedContent]);
          setDislikedContent([...currentdislikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const dislikeHandler = (title) => {
    let eventkey = { event: "dislikecontent", value: title };
    var currentlikedContent = [...likedContent];
    var currentdislikedContent = [...dislikedContent];
    currentdislikedContent.push(title);
    // if content in liked array remove it from there
    if (currentlikedContent.includes(title)) {
      const index = currentlikedContent.indexOf(title);
      currentlikedContent.splice(index, 1);
      eventkey = { event: "dislikecontent-removelike", value: title };
    }
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setDislikedContent([...currentdislikedContent]);
          setLikedContent([...currentlikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const removeLikeHandler = (title) => {
    let eventkey = { event: "removelikecontent", value: title };

    var currentlikedContent = [...likedContent];
    const index = currentlikedContent.indexOf(title);
    currentlikedContent.splice(index, 1);
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setLikedContent([...currentlikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const removeDislikeHandler = (title) => {
    let eventkey = { event: "removedislikecontent", value: title };
    var currentdislikedContent = [...dislikedContent];
    const index = currentdislikedContent.indexOf(title);
    currentdislikedContent.splice(index, 1);
    let metricData = userMetricsData;
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setDislikedContent([...currentdislikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const saveHandler = (title) => {
    let eventkey = { event: "savedcontent", value: title };
    let allcontents = [...contents];
    let playlist = [...playList];
    let currentSavedContent = [...savedContent];
    let currentRecommendContent = [...recommendedContent];
    let metricData = userMetricsData;
    if (currentSavedContent.includes(title)) {
      const index = currentSavedContent.indexOf(title);
      currentSavedContent.splice(index, 1);
      eventkey = { event: "removeSaved", value: title };
      if (!currentRecommendContent.includes(title)) {
        allcontents.splice(
          allcontents.findIndex((a) => a.title === title),
          1
        );
        playlist.splice(
          playlist.findIndex((a) => a.title === title),
          1
        );
        if (allcontents.length == 0) {
          setNoContent(true);
        }
      } else {
        if (contentFilter == "saved") {
          allcontents.splice(
            allcontents.findIndex((a) => a.title === title),
            1
          );
        }
      }
    } else {
      currentSavedContent.push(title);
    }
    metricData["savedcontent"] = [...currentSavedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setSavedContent([...currentSavedContent]);
          setUserMetricsData(metricData);
          setContents(allcontents);
          setPlayList(playlist);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const getContentLinkHandler = (item) => {
    call(`content/${item.title}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setPlayContent(true);
          setContent(res.data);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log("error is", err);
        Sentry.captureException(err);
      });
  };

  const closeContentPlayer = () => {
    setPlayContent(false);
  };

  const getSavedContent = () => {
    let currentSavedContent = [...savedContent];
    let CuttentPlayList = [...playList];
    let newList = CuttentPlayList.filter((item) => {
      return currentSavedContent.includes(item.title);
    });
    setContents([...newList]);
    if (newList.length == 0) {
      setNoContent(true);
    } else {
      setNoContent(false);
    }
  };

  const getRecommendContent = () => {
    let currentRecommendContent = [...recommendedContent];
    let CuttentPlayList = [...playList];
    let newList = CuttentPlayList.filter((item) => {
      return currentRecommendContent.includes(item.title);
    });
    setContents([...newList]);
    if (newList.length == 0) {
      setNoContent(true);
    } else {
      setNoContent(false);
    }
  };

  const getAllContent = () => {
    setContents([...playList]);
    if (playList.length == 0) {
      setNoContent(true);
    } else {
      setNoContent(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={`My Content`} />

      <Grid container spacing={1} mt={2}>
        <Grid item xs={12} md={12} flex={1}>
          <Card style={{ height: "100%" }}>
            <MDBox py={4} px={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={4}>
                  <MDButton
                    variant={contentFilter == "all" ? "contained" : "outlined"}
                    color="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setContentFilter("all");
                      getAllContent();
                    }}
                  >
                    My Content
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <MDButton
                    variant={contentFilter == "saved" ? "contained" : "outlined"}
                    color="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setContentFilter("saved");
                      getSavedContent();
                    }}
                  >
                    Saved Content
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <MDButton
                    variant={contentFilter == "recommend" ? "contained" : "outlined"}
                    color="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setContentFilter("recommend");
                      getRecommendContent();
                    }}
                  >
                    Recommended Content
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            {contents.length > 0 && (
              <Grid container spacing={3} mt={0}>
                <Grid item xs={12} md={12} flex={1}>
                  <MDBox
                    style={{ height: "100%" }}
                    bgColor="white"
                    variant="gradient"
                    borderRadius="xl"
                    sx={{
                      background: ({ palette: { background } }) => false && background.card,
                    }}
                  >
                    <MDBox py={2} px={2} style={{ height: "100%" }}>
                      <MDBox py={2}>
                        <Grid container spacing={6}>
                          {contents.map((item, index) => {
                            let icon = "movie";
                            let content_type = item.content_type.toLowerCase();
                            if (content_type == "video") {
                              icon = "movie";
                            } else if (content_type == "infographic") {
                              icon = "leaderboard";
                            } else if (content_type == "article") {
                              icon = "article";
                            } else if (content_type == "worksheet") {
                              icon = "note";
                            }
                            return (
                              <Grid item xs={12} md={4}>
                                <DefaultProjectCard
                                  item={item}
                                  icon={icon}
                                  type={item.content_type}
                                  title={item.title}
                                  shareable={false}
                                  content_url={item.content_url}
                                  image={item.thumbnail_url || MaxuLogo}
                                  getContentLinkHandler={getContentLinkHandler}
                                  liked={likedContent.includes(item.title)}
                                  disliked={dislikedContent.includes(item.title)}
                                  saved={savedContent.includes(item.title)}
                                  viewed={viewedContent.includes(item.title)}
                                  likeHandler={likeHandler}
                                  dislikeHandler={dislikeHandler}
                                  removeLikeHandler={removeLikeHandler}
                                  removeDislikeHandler={removeDislikeHandler}
                                  saveHandler={saveHandler}
                                  // shareHandler={shareHandler}
                                  action={{
                                    type: "external",
                                    route: item.content_url,
                                    color: "info",
                                    label: "view",
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>

      {playContent && (
        <ContentPlayerDialog
          playContent={playContent}
          closeContentPlayer={closeContentPlayer}
          content={content}
          userMetricsData={userMetricsData}
          setUserMetricData={setUserMetricsData}
          setViewedContent={setViewedContent}
          userId={profile.id}
          setSessionExpired={setSessionExpired}
        />
      )}

      {noContent && (
        <MDBox mt={2.5}>
          <Card style={{ backgroundColor: "#49a3f1", padding: 20, opacity: 0.8 }}>
            <MDBox display="flex" flexDirection="column">
              <MDTypography
                textAlign="center"
                justifyContent="center"
                component="h3"
                fontWeight="medium"
                color="white"
                flexWrap="wrap"
              >
                You have no content available here
              </MDTypography>
              <MDTypography
                textAlign="center"
                component="h3"
                variant="body2"
                fontWeight="medium"
                color="white"
                alignItems="center"
                flexWrap="wrap"
              >
                If you think you've reached this message in error, please contact support at{" "}
                <a href="mailto:support@maxu.co"> support@maxu.co </a>
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      )}

      {loading && (
        <Dialog open={loading} fullScreen>
          <DialogContent>
            <Loader loaderText="Loading Content..." />
          </DialogContent>
        </Dialog>
      )}

      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

export default PlayList;

const ContentPlayerDialog = ({
  playContent,
  closeContentPlayer,
  content,
  userMetricsData,
  setUserMetricData,
  setViewedContent,
  userId,
  setSessionExpired,
}) => {
  let src = content.content_url;
  let type = content.content_type.toLowerCase();
  let title = content.title;
  useEffect(() => {
    if (type == "video") {
      updateMetrics();
    }
  }, []);

  const updateMetrics = () => {
    let viewedcontent = userMetricsData["viewedcontent"];
    if (viewedcontent.includes(title)) {
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    } else {
      userMetricsData["viewedcontent"] = [...viewedcontent, title];
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    }
    call(`users/${userId}/contentmetrics`, "PUT", { body: userMetricsData })
      .then((res) => {
        if (res.status == 200) {
          setUserMetricData(userMetricsData);
          setViewedContent([...userMetricsData.viewedcontent]);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };
  return (
    <Dialog open={playContent} onClose={closeContentPlayer} fullWidth={true} maxWidth={"md"}>
      <CancelPresentationIcon
        fontSize="large"
        onClick={closeContentPlayer}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>
        {type == "video" ? (
          <ReactPlayer url={src} playing={true} controls={true} width="100%" />
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignSelf="end"
            alignItems="center"
            onClick={closeContentPlayer}
          >
            <MDButton
              onClick={updateMetrics}
              component={"a"}
              href={src}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="large"
              color={"info"}
            >
              Open in new tab
            </MDButton>
          </MDBox>
        )}
      </DialogContent>
    </Dialog>
  );
};
