import { Box, Button, Card, Grid, Modal, Typography } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useRef, useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ChildrenTable from "./ChildrenTable";
import CloseIcon from "@mui/icons-material/Close";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { call } from "services/axiosService/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import MDButton from "components/MDButton";
import ChangePassword from "./ChangePassword";
import Refresh from "pages/refresh/Refresh";
import { useNavigate, useLocation } from "react-router-dom";

// Otis Admin PRO React contexts
import { useMaterialUIController, setProfile } from "context";
import Snackbar from "@mui/material/Snackbar";
import * as Sentry from "@sentry/browser";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GuardianProfile = () => {
  const location = useLocation();
  const [openAddChild, setOpenAddChild] = useState(
    location.state ? location.state.openAddChild : false
  );
  const [openPayment, setOpenPayment] = useState(
    location.state ? location.state.openPayment : false
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [children, setChildren] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openProfilePictureModal, setOpenProfilePictureModal] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { profile, persona } = controller;
  const [title, setTitle] = useState("Guardian");
  const [headingTitle, setHeadingTitle] = useState("Guardian Profile");
  const [loader, setLoader] = useState(true);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const openProfilePictureModalHandler = () => {
    setOpenProfilePictureModal(true);
  };

  const closeProfilePictureModal = () => {
    setOpenProfilePictureModal(false);
  };

  const openEditModalHandler = () => {
    setOpenEditModal(true);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (persona != "admin" && persona != "contentManager") {
      getChildrens();
    }
    setName(profile.details.name);
    setEmail(profile.email);
  }, []);

  useEffect(() => {
    switch (persona) {
      case "admin":
        setTitle("Admin");
        setHeadingTitle("Admin Profile");
        return;
      case "guardian":
        setTitle("Guardian");
        setHeadingTitle("Guardian Profile");
        return;
      case "contentManager":
        setTitle("Content Manager");
        setHeadingTitle("conentManager Profile");
        return;
      default:
        setTitle("Guardian");
        setHeadingTitle("Guardian Profile");
        return;
    }
  });

  const getChildrens = () => {
    setLoader(true);
    call(`users/${profile.id}`, "GET", { params: { type: "children" } })
      .then((response) => {
        if (response.status == 200) {
          if (persona == "adultAthlete") {
            setChildren([profile, ...response.data]);
          } else {
            setChildren(response.data);
          }
          setLoader(false);
        } else if (response.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch(function (error) {
        Sentry.captureException(error);
        console.log(error);
        setLoader(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={headingTitle} />
      <Grid item xs="10" mt={2}>
        <Card style={{ padding: "30px" }}>
          <Grid display="flex" gap={3} alignItems="center">
            <Grid item style={{ position: "relative", paddingRight: "10px" }}>
              {profile.profile_image_url == null ? (
                <Avatar
                  alt="athlete-profile-image"
                  shadow="sm"
                  sx={{ height: "90px", width: "90px" }}
                >
                  <PersonIcon sx={{ height: "85px", width: "85px" }} color="white" />
                </Avatar>
              ) : (
                <MDAvatar
                  src={profile.profile_image_url}
                  alt="athlete-profile-image"
                  size="xxl"
                  shadow="sm"
                />
              )}
              <EditIcon
                onClick={openProfilePictureModalHandler}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  color: "#0AB2D6",
                }}
              />
            </Grid>

            <Grid item xs="8">
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h4" fontWeight="medium">
                  {name}
                </MDTypography>
                <MDTypography variant="h5" color="text" fontWeight="regular">
                  {title}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>

          <MDTypography variant="h4" mt={1} mb={2}>
            Profile Information
          </MDTypography>
          <MDBox display="flex" justifyContent="" gap="10px">
            <MDTypography variant="p" fontSize={15} mb={1} ml={2}>
              <b>Name: </b>
              {name}
            </MDTypography>
            <EditIcon
              onClick={openEditModalHandler}
              style={{ marginLeft: "10px", cursor: "pointer", color: "#0AB2D6" }}
            />
          </MDBox>
          <MDTypography variant="p" fontSize={15} mb={1} ml={2}>
            <b>Email: </b>
            {email}
          </MDTypography>
          <MDButton
            variant="contained"
            color="primary"
            style={{ width: 220, marginLeft: 15 }}
            onClick={() => {
              setOpenChangePasswordDialog(true);
            }}
          >
            Change Password
          </MDButton>
          {persona != "admin" && persona != "contentManager" && (
            <ChildrenTable
              childrenDetails={children}
              getChildrens={getChildrens}
              loader={loader}
              setSessionExpired={setSessionExpired}
              openAddChild={openAddChild}
              openPayment={openPayment}
            />
          )}
        </Card>
      </Grid>

      {openProfilePictureModal && (
        <ProfilePictureDialog
          openProfilePictureModal={openProfilePictureModal}
          closeProfilePictureModal={closeProfilePictureModal}
          profile={profile}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openEditModal && (
        <ProfileEditDialog
          openEditModal={openEditModal}
          closeEditModal={closeEditModal}
          profile={profile}
          setName={setName}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openChangePasswordDialog && (
        <ChangePassword
          openChangePasswordDialog={openChangePasswordDialog}
          setOpenChangePasswordDialog={setOpenChangePasswordDialog}
          setOpenSnackbar={setOpenSnackbar}
          setSeverity={setSeverity}
          setAlertMessage={setAlertMessage}
        />
      )}

      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

const ProfilePictureDialog = ({
  openProfilePictureModal,
  closeProfilePictureModal,
  profile,
  setSessionExpired,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [controller, dispatch] = useMaterialUIController();

  const removePhotoHanler = () => {
    setIsLoading(true);
    profile.profile_image_url = null;
    call(`users/${profile.id}`, "PUT", { body: profile })
      .then((res) => {
        if (res.status == 200) {
          setProfile(dispatch, profile);
          setIsLoading(false);
          closeProfilePictureModal(null);
        } else if (res.status == 440) {
          setIsLoading(false);
          closeProfilePictureModal(null);
          setSessionExpired(true);
        } else {
          console.log("error while updating user");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setIsLoading(false);
        closeProfilePictureModal();
      });
  };

  const uploadPhotoHandler = (e) => {
    setIsUploading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const params = {
      upload: "image",
    };
    const headers = {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": "multipart/form-data",
    };

    call(`users/${profile.id}`, "PUT", { headers: headers, body: data, params: params })
      .then((res) => {
        if (res.status == 200) {
          profile.profile_image_url = res.data.profile_image_url;
          setProfile(dispatch, profile);
          setIsUploading(false);
          closeProfilePictureModal();
        } else if (res.status == 440) {
          setIsUploading(false);
          closeProfilePictureModal();
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setIsUploading(false);
        closeProfilePictureModal();
      });
  };

  return (
    <Dialog open={openProfilePictureModal} onClose={closeProfilePictureModal}>
      <CloseIcon
        fontSize="medium"
        onClick={closeProfilePictureModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>
        <MDTypography variant="h4" mb={3}>
          Upload Profile Picture
        </MDTypography>
      </DialogTitle>

      <DialogContent>
        <Grid container justify="flex-start">
          <Grid item style={{ position: "relative", paddingRight: "10px" }}>
            {profile.profile_image_url == null ? (
              <Avatar
                alt="athlete-profile-image"
                shadow="sm"
                sx={{ height: "90px", width: "90px" }}
              >
                <PersonIcon sx={{ height: "85px", width: "85px" }} color="white" />
              </Avatar>
            ) : (
              <MDAvatar
                src={profile.profile_image_url}
                alt="athlete-profile-image"
                size="xxl"
                shadow="sm"
              />
            )}
          </Grid>
          <Grid item>
            <button
              style={{
                display: "block",
                width: "120px",
                height: "30px",
                margin: "15px 10px 10px 10px",
                color: "white",
                background: "#F6821E",
              }}
              onClick={() => {
                document.getElementById("getFile").click();
              }}
            >
              {isUploading ? "uploading..." : "Upload Photo"}
            </button>
            <input
              type="file"
              id="getFile"
              accept=".png, .jpg, .jpeg, .svg, .ico, .gif"
              onChange={uploadPhotoHandler}
              style={{ display: "none" }}
            ></input>
            {profile.profile_image_url != null && (
              <button
                style={{
                  display: "block",
                  width: "120px",
                  height: "30px",
                  margin: "10px",
                  color: "white",
                  background: "#d4242d",
                }}
                onClick={removePhotoHanler}
              >
                {isLoading ? "Removing..." : "Remove Photo"}
              </button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const ProfileEditDialog = ({
  openEditModal,
  closeEditModal,
  profile,
  setName,
  setSessionExpired,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const [firstName, setFirstName] = useState(profile.details.name.split(" ")[0]);
  const [lastName, setLastName] = useState(profile.details.name.split(" ")[1] ?? null);

  const editSubmitHandler = (e) => {
    e.preventDefault();
    const nameValue = firstName + " " + lastName;
    const body = {
      pk: profile.pk ?? "user",
      sk: profile.sk ?? `user[${profile.id}]`,
      details: {
        address: {
          city: profile.details.address.city,
          country: profile.details.address.country,
          state: profile.details.address.state,
          street: profile.details.address.street,
          zip: profile.details.address.zip,
        },
        dob: profile.details.dob,
        persona: profile.details.persona ?? null,
        name: nameValue,
        gender: profile.details.gender ?? null,
      },
      email: profile.email,
      phone: profile.phone,
      id: profile.id,
      cognito_user_id: profile.cognito_user_id,
      identities: profile.identities,
      profile_image_url: profile.profile_image_url,
      relationships: profile.relationships,
      status: profile.status,
      payment_status: profile.payment_status,
      type: profile.type,
    };

    call(`users/${profile.id}`, "PUT", { body: body })
      .then((res) => {
        if (res.status == 200) {
          closeEditModal();
          setProfile(dispatch, body);
          setName(res.data.ModifiedAttributes.details.name);
        } else if (res.status == 440) {
          closeEditModal();
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
  };
  return (
    <Dialog open={openEditModal} onClose={closeEditModal}>
      <CloseIcon
        fontSize="medium"
        onClick={closeEditModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>
        <MDTypography variant="h4" mb={1}>
          Edit Profile
        </MDTypography>
      </DialogTitle>

      <form onSubmit={editSubmitHandler}>
        <DialogContent>
          <Typography variant="h6" textAlign="left" mb={2}>
            First Name:
            <MDInput
              rows="1"
              name="firstname"
              type="text"
              value={firstName}
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setFirstName(e.target.value);
                }
              }}
              multiline
              fullWidth
              required
            />
          </Typography>
          <Typography variant="h6" textAlign="left" mb={2}>
            Last Name:
            <MDInput
              rows="1"
              name="lastname"
              type="text"
              value={lastName}
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setLastName(e.target.value);
                }
              }}
              multiline
              fullWidth
              required
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            style={{
              color: "white",
              background: "#0AB2D6",
              display: "block",
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GuardianProfile;
