// badges Name and logo can be changes
// We can't change the key value. we are collecting progress based on key value 
const badgesData = [
    {
        "logo": "TheExplorer",
        "name": "The Explorer",
        "description": "Took first assessment",
        "key": "The Explorer"
    },
    {
        "logo": "TheWatcher",
        "name": "The Watcher",
        "description": "Viewed first content",
        "key": "The Watcher",
    },
    {
        "logo": "TheBeliever",
        "name": "The Believer",
        "description": "Viewed first “shared” content",
        "key": "The Believer",
    },
    {
        "logo": "ThePhantom",
        "name": "The Phantom",
        "description": "Liked/saved first content",
        "key": "The Phantom",
    },
    {
        "logo": "LikedUser",
        "name": "I Like It",
        "description": "Liked 10+ contents",
        "key": "Liked User",
    },
    {
        "logo": "TheSuperWatcher",
        "name": "The Super Watcher",
        "description": "Viewed 50+ contents",
        "key": "The Super Watcher"
    },
    {
        "logo": "SilverUser",
        "name": "Silver User",
        "description": "Logged in every week for 3 months",
        "key": "Silver User",
    },
    {
        "logo": "GoldenUser",
        "name": "Golden User",
        "description": "Logged in every week for a year",
        "key": "Golden User",
    },
]

export default badgesData;
