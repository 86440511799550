import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@mui/material/Autocomplete";
import { call } from "services/axiosService/core";
import axios from "axios";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
  useMediaQuery,
  CircularProgress,
  Radio,
  RadioGroup,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";
import Loader from "pages/loader/Loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CardMedia from "@mui/material/CardMedia";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddContent = () => {
  const [upload, setUpload] = useState(false);
  const [showUploadContent, setShowUploadContent] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [tagFields, setTagFields] = useState([{ tag_name: "category", tag_value: "" }]);
  const [contentURLList, setCotentURLList] = useState(["Upload", "ExternalLink"]);
  const [contentFile, setContentFile] = useState(null);
  const [preSignedURL, setPreSignedURL] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [contentFileType, setContentFileType] = useState("ExternalLink");
  const [disableuploadButton, setdisableUploadButton] = useState(true);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [item, setItem] = useState({});
  const [sessionExpired, setSessionExpired] = useState(false);
  const [step, setStep] = useState(1);
  const [thumbnailOption, setThumbnailOption] = useState("upload");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [contentDetails, setContentDetails] = useState({});
  const [showGenerateProgress, setShowGenerateProgress] = useState(false);
  const [thumbnailGalary, setThumbnailGalary] = useState([]);

  const handleImageClick = (url) => {
    setThumbnailUrl(url);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const contentTypeList = ["Video", "Worksheet", "Infographic", "Article"];

  const urlRegExp = "^(http|https)://";
  const schema = yup.object().shape({
    title: yup.string().trim().required("You must enter the title of content"),
    type: yup.string().trim().required("You must enter the type of content"),
    url_type: yup.string().trim().required("You must enter the URL type of content"),
    weight: yup.number().max(10).min(0).required("You must enter Weight of content"),
  });

  const defaultValues = {
    title: "",
    type: "Video",
    url_type: "ExternalLink",
    url: "",
    weight: 1,
  };

  const { handleSubmit, reset, control, formState, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const contentURLChangeHandler = (val) => {
    setContentFileType(val);
  };

  const contentDataHandler = (data) => {
    const contentdata = {};
    contentdata["title"] = data["title"];
    contentdata["weight"] = data["weight"].toString();
    contentdata["content_type"] = data["type"];
    if (data["url_type"] == "Upload") {
      contentdata["upload_content"] = true;
      contentdata["content_url"] = null;
    } else {
      contentdata["upload_content"] = false;
      contentdata["content_url"] = data["url"];
    }
    const content_tags = {};
    tagFields.map((tag) => {
      content_tags[tag["tag_name"]] = tag["tag_value"].split(",");
    });
    contentdata["tags"] = content_tags;
    setContentDetails(contentdata);
    setStep(2);
  };

  const addContentHandler = () => {
    setShowCircularProgress(true);
    const formdata = new FormData();
    const contentdata = {};
    contentdata["thumbnailOption"] = thumbnailOption;
    contentdata["thumbnailUrl"] = thumbnailUrl;
    contentdata["title"] = contentDetails.title;
    contentdata["weight"] = contentDetails.weight;
    contentdata["content_type"] = contentDetails.content_type;
    contentdata["upload_content"] = contentDetails.upload_content;
    contentdata["content_url"] = contentDetails.content_url;
    contentdata["tags"] = contentDetails.tags;
    formdata.append("file", thumbnail);
    formdata.append("data", JSON.stringify(contentdata));
    const headers = {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": "multipart/form-data",
    };

    call(`content/`, "POST", { headers: headers, body: formdata })
      .then((res) => {
        setShowCircularProgress(false);
        if (res.status == 200) {
          setThumbnailOption("upload");
          setThumbnailUrl("");
          setThumbnailGalary([]);
          setTagFields([{ tag_name: "category", tag_value: "" }]);
          // setCotentURLList(["Upload", "ExternalLink"]);
          setOpenSnackbar(true);
          setAlertMessage("Content Added Sucessfully");
          setSeverity("success");
          let s = document.getElementById("getFile");
          if (s) {
            s.value = null;
          }
          reset(defaultValues);
          if (contentFileType == "Upload") {
            setStep(3);
          } else {
            setStep(1);
          }
        } else if (res.status == 201) {
          setTagFields([{ tag_name: "category", tag_value: "" }]);
          // setCotentURLList(["Upload", "ExternalLink"]);
          let s = document.getElementById("getFile");
          if (s) {
            s.value = null;
          }
          reset(defaultValues);
          setThumbnailOption("upload");
          setThumbnailUrl("");
          setThumbnailGalary([]);
          setPreSignedURL(res.data.presigned_url);
          setItem(res.data.item);
          setStep(3);
        } else if (res.status == 440) {
          setSessionExpired(true);
          setThumbnailGalary([]);
        } else {
          setOpenSnackbar(true);
          setThumbnailGalary([]);
          setAlertMessage(res.data.message);
          setSeverity("error");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setShowCircularProgress(false);
        setOpenSnackbar(true);
        setAlertMessage("Error while adding the content");
        setSeverity("error");
      });
  };

  const changeTagHandler = (index, event) => {
    let data = [...tagFields];
    data[index][event.target.name] = event.target.value;
    setTagFields(data);
  };

  const addTagHandler = () => {
    let data = [...tagFields, { tag_name: "", tag_value: "" }];
    setTagFields(data);
  };

  const removeTagHandler = (index) => {
    let data = [...tagFields];
    data.splice(index, 1);
    setTagFields(data);
  };

  async function uploadThumbnailHandler(e) {
    if (e.target.files[0]) {
      // let img = new Image();
      // img.src = URL.createObjectURL(e.target.files[0]);
      // await img.decode();
      // let width = img.width;
      // let height = img.height;
      // if (width != 1706 || height != 1229) {
      //   setOpenSnackbar(true);
      //   setAlertMessage("Please select the image with dimentions 1706 x 1229");
      //   setSeverity("error");
      //   let s = document.getElementById("getFile");
      //   s.value = null;
      // }
      if (e.target.files[0].size > 1e7) {
        setOpenSnackbar(true);
        setAlertMessage("Please upload a file smaller than 10 MB");
        setSeverity("error");
        let s = document.getElementById("getFile");
        s.value = null;
      } else {
        // const reader = new FileReader();
        // setImageSrc(URL.createObjectURL(e.target.files[0]))
        // reader.onloadend = () => {
        //   // Set the image source once the file is read
        //   setImageSrc(reader.result);
        // };
        // reader.readAsDataURL(e.target.files[0]);
        setThumbnail(e.target.files[0]);
      }
    }
  }

  const uploadContentHandler = (e) => {
    if (e.target.files[0]) {
      setdisableUploadButton(false);
    } else {
      setdisableUploadButton(true);
    }
    setContentFile(e.target.files[0]);
  };
  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setProgressValue(percent);
  };

  const pushcontentHandler = () => {
    setdisableUploadButton(true);
    setShowProgress(true);
    axios
      .put(preSignedURL, contentFile, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": contentFile.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "PUT,GET,POST,DELETE,OPTIONS",
        },

        onUploadProgress,
      })
      .then(function (response) {
        const headers = {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": "multipart/form-data",
        };
        const formdata = new FormData();
        const contentdata = item;

        let url = preSignedURL.split("?")[0];
        contentdata["content_url"] = url;
        contentdata["push_content"] = true;
        formdata.append("data", JSON.stringify(contentdata));
        call(`content/${item.title}/`, "PUT", { headers: headers, body: formdata })
          .then((res) => {
            if (res.status == 200) {
              setUploadCompleted(true);
              setOpenSnackbar(true);
              setAlertMessage("Content Added Sucessfully");
              setSeverity("success");
            } else if (res.status == 440) {
              setSessionExpired(true);
            }
          })
          .catch((err) => {
            setUploadCompleted(true);
            Sentry.captureException(err);
            console.log("Error while updating contenr");
            setOpenSnackbar(true);
            setAlertMessage("Error while uploading the content");
            setSeverity("error");
          });
      })
      .catch(function (error) {
        Sentry.captureException(error);
        console.log(error);
      });
  };

  const backtocontentHandler = () => {
    let s = document.getElementById("contentFile");
    s.value = null;
    setContentFileType("ExternalLink");
    setUploadCompleted(false);
    setShowProgress(false);
    setProgressValue(0);
    setPreSignedURL(null);
    setItem({});
    setThumbnail(null);
    setStep(1);
  };

  const handleThumbnailOptionChange = (event) => {
    setThumbnailOption(event.target.value);
    if (event.target.value == "generate") {
      setThumbnail(null);
      if (thumbnailGalary.length) {
        setThumbnailUrl(thumbnailGalary[0].url);
      }
    } else {
      setThumbnailUrl(null);
    }
  };

  const getAIGeneratedImageHandler = (jobId) => {
    const GetAIGeneratedThumbanil = () => {
      call(`content/thumbnail/${jobId}`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            setShowGenerateProgress(false);
            clearTimeout(myTimeout);
            clearInterval(myInterval);
            let options = res.data.thumbnailUrl;
            const images = options.map((url, index) => ({
              id: index + 1,
              url: url,
            }));
            setThumbnailGalary(images);
            setThumbnailUrl(options[0]);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          setShowGenerateProgress(false);
          setOpenSnackbar(true);
          setAlertMessage("Error while creating thumbnail");
          setSeverity("error");
        });
    };

    let myInterval = setInterval(GetAIGeneratedThumbanil, 20000);
    let myTimeout = setTimeout(() => {
      setShowGenerateProgress(false);
      clearInterval(myInterval);
    }, 150000);
  };

  const handleGenerateThumbnail = () => {
    call(`content/thumbnail`, "POST", { body: { title: contentDetails.title } })
      .then((res) => {
        if (res.status == 200) {
          setShowGenerateProgress(true);
          getAIGeneratedImageHandler(res.data.job_id);
          setOpenSnackbar(true);
          setAlertMessage("thumbnail Generation is started, it will take under a minute");
          setSeverity("info");
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        setOpenSnackbar(true);
        setAlertMessage("Error while creating thumbnail");
        setSeverity("error");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={`Add Content`} />
      {step == 1 && (
        <MDBox mt={3} display="flex" flex={1} justifyContent="center">
          <form onSubmit={handleSubmit((data) => contentDataHandler(data))}>
            <Card style={{ height: "100%", width: "100%" }}>
              <Grid container spacing={1} p={3}>
                <Grid item xs={12} xl={12}>
                  <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                    Title:
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        sx={{ height: 50 }}
                        size="large"
                        error={!!errors.title}
                        helperText={errors?.title?.message}
                        {...field}
                        inputProps={{
                          autoComplete: "new-title",
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="title"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                  <FormLabel sx={{ marginBottom: "10px", fontSize: "20px" }}>
                    Content Type:
                  </FormLabel>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={""}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        size="large"
                        multiple={false}
                        options={contentTypeList}
                        value={value}
                        onChange={(event, newValue) => {
                          // typeChangeHandler(newValue);
                          onChange(newValue);
                        }}
                        limitTags={3}
                        renderInput={(params) => (
                          <TextField
                            sx={{ height: 50 }}
                            size="large"
                            error={!!errors.type}
                            helperText={errors?.type?.message}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-type",
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                  <FormLabel sx={{ marginBottom: "10px", fontSize: "20px" }}>
                    Content URL:
                  </FormLabel>
                  <Controller
                    name="url_type"
                    control={control}
                    defaultValue={""}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        size="large"
                        multiple={false}
                        options={contentURLList}
                        value={value}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          contentURLChangeHandler(newValue);
                        }}
                        limitTags={3}
                        renderInput={(params) => (
                          <TextField
                            sx={{ height: 50 }}
                            size="large"
                            error={!!errors.url_type}
                            helperText={errors?.url_type?.message}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-url-type",
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                {contentFileType == "ExternalLink" && (
                  <MDBox pt={3} width="100%" pl={1}>
                    <Grid item xs={12} xl={12}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            sx={{ height: 50 }}
                            placeholder="Add ExternalURL Here"
                            size="large"
                            required
                            {...field}
                            inputProps={{
                              autoComplete: "new-url",
                            }}
                            required
                            error={
                              !getValues("url").match(urlRegExp) && getValues("url").length > 0
                            }
                            helperText={
                              !getValues("url").match(urlRegExp) && getValues("url").length > 0
                                ? "Not a Valid URL"
                                : ""
                            }
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        name="url"
                        control={control}
                      />
                    </Grid>
                  </MDBox>
                )}
                {/* {contentFileType == "InlineText" && (
                  <MDBox pt={3} width="100%" pl={1}>
                    <Grid item xs={12} xl={12}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            sx={{ height: 100 }}
                            placeholder="Add Content Text Here"
                            multiline
                            rows={2}
                            size="large"
                            required
                            {...field}
                            inputProps={{
                              autoComplete: "new-content-text",
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        name="content_text"
                        control={control}
                      />
                    </Grid>
                  </MDBox>
                )} */}
                <Grid item xs={12} xl={12}>
                  <Grid
                    container
                    spacing={1}
                    border={1}
                    boxShadow={"5"}
                    mt={2}
                    mb={2}
                    borderColor={"#49a3f1"}
                  >
                    <Grid item xs={12} xl={12}>
                      <FormLabel sx={{ marginBottom: "10px", fontSize: "20px" }}>
                        Add Tags:
                      </FormLabel>
                    </Grid>
                    {tagFields.map((tag, index) => {
                      return (
                        <Grid
                          container
                          spacing={1}
                          border={1}
                          m={2}
                          pb={1}
                          boxShadow={"3"}
                          borderColor={"#0c85ed"}
                        >
                          <Grid item xs={12} sm={12} md={4} lg={5} xl={5} pr={1}>
                            <TextField
                              sx={{ height: 50 }}
                              required
                              name="tag_name"
                              placeholder="Tag Name"
                              value={tag.tag_name}
                              size="large"
                              onChange={(event) => changeTagHandler(index, event)}
                              inputProps={{
                                autoComplete: "new-tage-name",
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={5} xl={5} pr={1}>
                            <TextField
                              sx={{ height: 50 }}
                              required
                              placeholder="Tag Value"
                              name="tag_value"
                              value={tag.tag_value}
                              size="large"
                              onChange={(event) => changeTagHandler(index, event)}
                              inputProps={{
                                autoComplete: "new-tage-value",
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                            {index != 0 && (
                              <MDBox display="flex" flex={1} justifyContent="center">
                                <MDButton
                                  style={{
                                    color: "white",
                                    background: "#f6821e",
                                    marginTop: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginRight: "5px",
                                  }}
                                  onClick={() => {
                                    removeTagHandler(index);
                                  }}
                                  type="button"
                                >
                                  Remove
                                </MDButton>
                              </MDBox>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12} xl={12} mb={2}>
                      <MDBox display="flex" justifyContent="center">
                        <MDButton
                          onClick={addTagHandler}
                          style={{
                            color: "white",
                            background: "#0AB2D6",
                            marginTop: "5px",
                            display: "flex",
                            marginRight: "5px",
                            justifyContent: "center",
                          }}
                          type="button"
                        >
                          Add More Tags
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                    Weight:
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        sx={{ height: 50 }}
                        type="number"
                        size="large"
                        error={!!errors.weight}
                        helperText={errors?.weight?.message}
                        {...field}
                        inputProps={{
                          autoComplete: "new-weight",
                          step: "0.1",
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="weight"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <MDBox display="flex" justifyContent="end">
                    <MDButton
                      style={{
                        color: "white",
                        background: "#0AB2D6",
                        marginTop: "15px",
                        display: "flex",
                        width: "100%",
                      }}
                      type="submit"
                    >
                      Next Step
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </form>
        </MDBox>
      )}
      {step == 2 && (
        <MDBox mt={3} display="flex" flex={1} justifyContent="center">
          <Card style={{ height: "100%", width: "100%" }}>
            <Grid container spacing={1} p={3}>
              <Grid item xs={12} xl={12}>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    style={{ marginBottom: "20px", fontSize: "25px" }}
                  >
                    Create Thumbnail for "{contentDetails.title}"
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={thumbnailOption}
                    onChange={handleThumbnailOptionChange}
                  >
                    <FormControlLabel
                      value="upload"
                      control={<Radio />}
                      style={{ marginRight: "40px" }}
                      label={<MDTypography variant="body1">Upload</MDTypography>}
                    />
                    <FormControlLabel
                      value="generate"
                      control={<Radio />}
                      label={<MDTypography variant="body1">Generate</MDTypography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} xl={12}>
                {thumbnailOption === "upload" && (
                  <>
                    <MDBox display="flex" alignItems="flex-start" justifyContent="start">
                      <Controller
                        render={({ field }) => (
                          <TextField
                            type="file"
                            id="getFile"
                            size="large"
                            required
                            style={{
                              display: "block",
                              borderRadius: "5px",
                              height: "100%",
                              marginTop: "3px",
                              color: "white",
                              background: "#0AB2D6",
                            }}
                            {...field}
                            onChange={uploadThumbnailHandler}
                            inputProps={{
                              accept: ".png",
                            }}
                            variant="outlined"
                          />
                        )}
                        name="file"
                        control={control}
                      />
                    </MDBox>
                    {/* {thumbnail && (
                      <MDBox display="flex" alignItems="flex-start" justifyContent="center">
                        <img
                          src={imageSrc}
                          style={{
                            marginTop: "25px",
                          }}
                          width="500"
                          height="500"
                          alt="Generated Thumbnail"
                        />
                      </MDBox>
                    )} */}

                    {showCircularProgress ? (
                      <MDBox display="flex" justifyContent="end">
                        <MDButton
                          style={{
                            color: "white",
                            background: "#0AB2D6",
                            marginTop: "15px",
                            display: "flex",
                            width: "100%",
                          }}
                          disabled
                          type="button"
                        >
                          <CircularProgress color="white" size={20} />
                        </MDButton>
                      </MDBox>
                    ) : (
                      <MDBox display="flex" justifyContent="end">
                        <MDButton
                          type="button"
                          style={{
                            color: "white",
                            background: "#0AB2D6",
                            marginTop: "25px",
                            display: "flex",
                            width: "100%",
                          }}
                          onClick={addContentHandler}
                        >
                          {contentFileType == "Upload" ? "Next Step" : "Add Content"}
                        </MDButton>
                      </MDBox>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} xl={12}>
                {thumbnailOption === "generate" && (
                  <div>
                    {!thumbnailUrl && (
                      <div>
                        {showGenerateProgress ? (
                          <MDButton
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "5px",
                              display: "flex",
                              width: "100%",
                            }}
                            disabled
                            type="button"
                          >
                            <CircularProgress color="white" size={20} />
                          </MDButton>
                        ) : (
                          <MDButton
                            type="button"
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "5px",
                              display: "flex",
                              width: "100%",
                            }}
                            onClick={handleGenerateThumbnail}
                          >
                            Generate Thumbnail
                          </MDButton>
                        )}
                      </div>
                    )}

                    {thumbnailUrl && (
                      <div>
                        <Grid container spacing={6}>
                          {thumbnailGalary.map((image) => (
                            <Grid item xs={12} md={3}>
                              <div
                                key={image.id}
                                style={{ margin: "5px", cursor: "pointer" }}
                                onClick={() => handleImageClick(image.url)}
                              >
                                <MDBox
                                  position="relative"
                                  flex={1}
                                  width="100.25%"
                                  shadow="xl"
                                  borderRadius="xl"
                                >
                                  <CardMedia
                                    src={image.url}
                                    component="img"
                                    height="100%"
                                    sx={{
                                      border:
                                        thumbnailUrl && thumbnailUrl === image.url
                                          ? "8px solid #0AB2D6"
                                          : "none",
                                      maxWidth: "100%",
                                      margin: 0,
                                      boxShadow: ({ boxShadows: { md } }) => md,
                                      objectFit: "cover",
                                      objectPosition: "center",
                                    }}
                                  />
                                </MDBox>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                        {showGenerateProgress ? (
                          <MDButton
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "5px",
                              display: "flex",
                              width: "100%",
                            }}
                            disabled
                            type="button"
                          >
                            <CircularProgress color="white" size={20} />
                          </MDButton>
                        ) : (
                          <MDButton
                            type="button"
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "5px",
                              display: "flex",
                              width: "100%",
                            }}
                            disabled={showCircularProgress}
                            onClick={handleGenerateThumbnail}
                          >
                            ReGenerate Thumbnail
                          </MDButton>
                        )}

                        {showCircularProgress ? (
                          <MDBox display="flex" justifyContent="end">
                            <MDButton
                              style={{
                                color: "white",
                                background: "#0AB2D6",
                                marginTop: "15px",
                                display: "flex",
                                width: "100%",
                              }}
                              disabled
                              type="button"
                            >
                              <CircularProgress color="white" size={20} />
                            </MDButton>
                          </MDBox>
                        ) : (
                          <MDButton
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "15px",
                              display: "flex",
                              width: "100%",
                            }}
                            disabled={showGenerateProgress}
                            onClick={addContentHandler}
                          >
                            {contentFileType == "Upload" ? "Next Step" : "Add Content"}
                          </MDButton>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Card>
        </MDBox>
      )}
      {step == 3 && (
        <MDBox mt={3} display="flex" flex={1} justifyContent="center">
          <Card style={{ height: "100%", width: "100%" }}>
            <Grid container spacing={1} p={3}>
              {showProgress && (
                <Grid item xs={12} xl={12}>
                  <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <MDBox
                      sx={{
                        width: { xs: "80%", sm: "80%", md: "90%", lg: "95%", xl: "95%" },
                        mr: 1,
                      }}
                    >
                      <ProgressBar bgcolor={"#f6831e"} completed={progressValue} />
                    </MDBox>
                    <MDBox sx={{ minWidth: 35 }}>
                      <MDTypography variant="body2" color="text.secondary">{`${Math.round(
                        progressValue
                      )}%`}</MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              )}
              <Grid item xs={12} xl={12}>
                <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                  Upload Content Here:
                </FormLabel>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  type="file"
                  disabled={showProgress}
                  id="contentFile"
                  size="large"
                  style={{
                    display: "block",
                    borderRadius: "5px",
                    marginTop: "3px",
                    color: "white",
                    background: "#0AB2D6",
                  }}
                  onChange={uploadContentHandler}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} xl={12}>
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    disabled={disableuploadButton}
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    type="button"
                    onClick={pushcontentHandler}
                  >
                    Upload
                  </MDButton>
                </MDBox>
              </Grid>
              {uploadCompleted && (
                <Grid item xs={12} xl={12}>
                  <MDBox display="flex" justifyContent="end">
                    <MDButton
                      style={{
                        color: "white",
                        background: "#0AB2D6",
                        marginTop: "15px",
                        display: "flex",
                        width: "100%",
                      }}
                      type="button"
                      onClick={backtocontentHandler}
                    >
                      Add More Content
                    </MDButton>
                  </MDBox>
                </Grid>
              )}
            </Grid>
          </Card>
        </MDBox>
      )}
      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;
  const containerStyles = {
    height: 10,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};

export default AddContent;
