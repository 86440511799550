import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDTypography from "components/MDTypography";
import { Button } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";

const Refresh = ({}) => {
  const { signOut } = useAuthenticator((context) => [context.user]);

  const logOutHandler = () => {
    localStorage.removeItem("session");
    localStorage.removeItem("referral");
    signOut();
  };

  return (
    <Dialog open={true} maxWidth={"xl"}>
      <DialogContent>
        <MDTypography>Current session is expired. You have been logged out.</MDTypography>
        <MDTypography>
          Refresh or{" "}
          <Button sx={{ padding: "0px 5px 0px 0px", fontSize: 18 }} onClick={logOutHandler}>
            login
          </Button>{" "}
          to continue
        </MDTypography>
      </DialogContent>
    </Dialog>
  );
};

export default Refresh;
