/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Otis Admin PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
import AthleteProfile from "pages/AthleteProfile/AthleteProfile";
import GuardianProfile from "pages/AthleteProfile/GuardianProfile";
import { Dashboard, AthleteDashboard, AdminDashboard, ContentDashboard } from "pages/dashboard";
import ContentLibrary from "pages/contentLibrary/ContentLibrary";
import PlayList from "pages/playList/PlayList";
import AddContent from "pages/addContent/AddContent";
import AddAnimalSummary from "pages/addAnimalSummary/addAnimalSummary";
import AddInsightsSummary from "pages/addInsightsSummary/addInsightsSummary";
import Faq from "pages/faq/Faq";
import Support from "pages/support/Support";
import { Teams } from "pages/teams";
import Achievements from "pages/achievements/Achievements";
import ChatBotData from "pages/chatBotData/ChatBotData";

// Any new routes should be added here also
export const allRoutes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <AthleteDashboard />,
  },
  {
    type: "route",
    name: "Content Library",
    key: "contentlibrary",
    route: "/contentlibrary",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <ContentLibrary />,
  },
  {
    type: "route",
    name: "My Content",
    key: "playlist",
    route: "/playlist",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <PlayList />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <AthleteProfile />,
  },
  {
    type: "route",
    name: "Support",
    key: "support",
    route: "/support",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <Support />,
  },
  {
    type: "route",
    name: "Best Practices/FAQs",
    key: "faq",
    route: "/faq",
    icon: <Icon fontSize="medium">faq</Icon>,
    component: <Faq />,
  },
  {
    type: "route",
    name: "My Achievements",
    key: "achievements",
    route: "/achievements",
    icon: <Icon fontSize="medium">faq</Icon>,
    component: <Achievements />,
    beta_route: false,
  },
  {
    type: "route",
    name: "Add Animal Data",
    key: "Add Animal Data",
    route: "/addAnimalSummary",
    icon: <Icon fontSize="medium">profile</Icon>,
    component: <AddAnimalSummary />,
  },
  {
    type: "route",
    name: "Add Insights Data",
    key: "Add Insights Data",
    route: "/addInsightsSummary",
    icon: <Icon fontSize="medium">profile</Icon>,
    component: <AddInsightsSummary />,
  },
  {
    type: "route",
    name: "ChatBot Data",
    key: "ChatBot Data",
    route: "/chatbotdata",
    icon: <Icon fontSize="medium">chat</Icon>,
    component: <ChatBotData />,
  },
]

export const athleteRoutes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <AthleteDashboard />,
  },
  {
    type: "route",
    name: "Content Library",
    key: "contentlibrary",
    route: "/contentlibrary",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <ContentLibrary />,
  },
  {
    type: "route",
    name: "My Content",
    key: "playlist",
    route: "/playlist",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <PlayList />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <AthleteProfile />,
  },
  {
    type: "route",
    name: "Support",
    key: "support",
    route: "/support",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <Support />,
  },
  {
    type: "route",
    name: "Best Practices/FAQs",
    key: "faq",
    route: "/faq",
    icon: <Icon fontSize="medium">faq</Icon>,
    component: <Faq />,
  },
  {
    type: "route",
    name: "My Achievements",
    key: "achievements",
    route: "/achievements",
    icon: <Icon fontSize="medium">faq</Icon>,
    component: <Achievements />,
    beta_route: false,
  },
];

export const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Dashboard />,
  },
  {
    type: "route",
    name: "Content Library",
    key: "contentlibrary",
    route: "/contentlibrary",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <ContentLibrary />,
  },
  {
    type: "route",
    name: "My Content",
    key: "playlist",
    route: "/playlist",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <PlayList />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <GuardianProfile />,
  },
  {
    type: "route",
    name: "Support",
    key: "support",
    route: "/support",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <Support />,
  },
  {
    type: "route",
    name: "Best Practices/FAQs",
    key: "faq",
    route: "/faq",
    icon: <Icon fontSize="medium">faq</Icon>,
    component: <Faq />,
  },
];

export const adultAthleteRoutes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Dashboard />,
  },
  {
    type: "route",
    name: "My Learner Dashboard",
    key: "mylearnerdashboard",
    route: "/mylearnerdashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <AthleteDashboard />,
  },
  {
    type: "route",
    name: "Content Library",
    key: "contentlibrary",
    route: "/contentlibrary",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <ContentLibrary />,
  },
  {
    type: "route",
    name: "My Content",
    key: "playlist",
    route: "/playlist",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <PlayList />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <GuardianProfile />,
  },
  {
    type: "route",
    name: "Support",
    key: "support",
    route: "/support",
    icon: <Icon fontSize="medium">support</Icon>,
    component: <Support />,
  },
  {
    type: "route",
    name: "Best Practices/FAQs",
    key: "faq",
    route: "/faq",
    icon: <Icon fontSize="medium">faq</Icon>,
    component: <Faq />,
  },
  {
    type: "route",
    name: "My Achievements",
    key: "achievements",
    route: "/achievements",
    icon: <Icon fontSize="medium">faq</Icon>,
    component: <Achievements />,
    beta_route: false,
  },
];

export const adminRoutes = [
  {
    type: "route",
    name: "Admin",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <AdminDashboard />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <GuardianProfile />,
  },
  {
    type: "route",
    name: "ChatBot Data",
    key: "ChatBot Data",
    route: "/chatbotdata",
    icon: <Icon fontSize="medium">chat</Icon>,
    component: <ChatBotData />,
  },
];

export const contentManagerRoutes = [
  {
    type: "route",
    name: "Dashboard",
    key: "Dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <ContentDashboard />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <GuardianProfile />,
  },
  {
    type: "route",
    name: "Add Content",
    key: "Add Content",
    route: "/addcontent",
    icon: <Icon fontSize="medium">profile</Icon>,
    component: <AddContent />,
  },
  {
    type: "route",
    name: "Add Animal Data",
    key: "Add Animal Data",
    route: "/addAnimalSummary",
    icon: <Icon fontSize="medium">profile</Icon>,
    component: <AddAnimalSummary />,
  },
  {
    type: "route",
    name: "Add Insights Data",
    key: "Add Insights Data",
    route: "/addInsightsSummary",
    icon: <Icon fontSize="medium">profile</Icon>,
    component: <AddInsightsSummary />,
  },
  {
    type: "route",
    name: "ChatBot Data",
    key: "ChatBot Data",
    route: "/chatbotdata",
    icon: <Icon fontSize="medium">chat</Icon>,
    component: <ChatBotData />,
  },
];
