// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
// Otis Admin PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import CssBaseline from "@mui/material/CssBaseline";
import { routes, athleteRoutes, adultAthleteRoutes, adminRoutes, contentManagerRoutes } from "routes";
import brandWhite from "assets/images/logo-ct.png";
import Icon from "@mui/material/Icon";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import { useState, useEffect } from "react";

// Otis Admin PRO React contexts
// import { useMaterialUIController, setOpenConfigurator } from "context";

function DashboardLayout({ children }) {
  // const [controller, dispatch] = useMaterialUIController();
  // const { openConfigurator } = controller;
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const [openSidenav, setOpenSidenav] = useState(false);

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );
  return (
    <MDBox
      display="flex"
      flex={1}
      height="100vh"
      flexDirection="column"
      justifyContent="space-between"
    >
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {openSidenav ? (
          <MDBox>
            <CssBaseline />
            <Sidenav
              brand={brandWhite}
              brandName="MaxU"
              routes={{ routes, athleteRoutes, adultAthleteRoutes, adminRoutes, contentManagerRoutes }}
              setOpenSidenav={setOpenSidenav}
            />
          </MDBox>
        ) : (
          <MDBox>
            <MDBox display={{ xs: "none", xl: "block" }}>
              <CssBaseline />
              <Sidenav
                brand={brandWhite}
                brandName="MaxU"
                routes={{ routes, athleteRoutes, adultAthleteRoutes, adminRoutes, contentManagerRoutes }}
                setOpenSidenav={setOpenSidenav}
              />
            </MDBox>
            <MDBox display={{ xs: "block", xl: "none" }}>
              <Icon
                fontSize="medium"
                color="inherit"
                onClick={() => {
                  setOpenSidenav(true);
                }}
              >
                menu
              </Icon>
            </MDBox>
          </MDBox>
        )}

        {/* <Configurator />
      {configsButton} */}

        {children}
      </MDBox>

      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <Card
          sx={{
            minHeight: "60px",
            justifyContent: "center",
            backgroundColor:"#49a3f1",
          }}
        >
          <Footer />
        </Card>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
