/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox  fontSize={size.md} key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography  fontSize={size.md} variant="button" fontWeight="regular" color="white">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.md}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}
        <Link href={href} target="_blank">
          <MDTypography color="white"  fontSize={size.md} variant="button" fontWeight="medium">
            &nbsp;{name}&nbsp;
          </MDTypography>
        </Link>
      
      </MDBox>
      <MDBox
        component="ul"
        fontSize={size.md} 
        fontSize= {size.md}
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: {
    href: "https://www.maxu.co",
    name: "Sunshine Analytics, LLC.",
  },
  links: [
    {
      href: "https://www.maxu.co",
      name: "About us",
    },
    {
      href: "https://maxu.co/#contact-maxu",
      name: "Contact us",
    },
  
    {
      href: "/terms_conditions",
      name: "Terms and Conditions",
    },

    {
      href: "/privacy_policy",
      name: "Privacy Policy",
    },
   
   
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.instanceOf(Object),
  links: PropTypes.instanceOf(Array),
};

export default Footer;
