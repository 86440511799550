import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@mui/material/Autocomplete";
import { call } from "services/axiosService/core";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddAnimalSummary = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [animalImage, setAnimalImage] = useState(null);
  const [recommendations, setRecommendations] = useState([""]);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const categoriesList = [
    "SR",
    "SC",
    "E",
    "R",
    "T+E+",
    "T-E+",
    "T+E-",
    "T-E-",
    "T+O+",
    "T-O+",
    "T+O-",
    "T-O-",
    "T+A+",
    "T-A+",
    "T+A-",
    "T-A-",
    "T+C+",
    "T-C+",
    "T+C-",
    "T-C-",
    "E+O+",
    "E-O+",
    "E+O-",
    "E-O-",
    "E+A+",
    "E-A+",
    "E+A-",
    "E-A-",
    "E+C+",
    "E-C+",
    "E+C-",
    "E-C-",
    "O+A+",
    "O-A+",
    "O+A-",
    "O-A-",
    "O+C+",
    "O-C+",
    "O+C-",
    "O-C-",
    "A+C+",
    "A-C+",
    "A+C-",
    "A-C-",
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const schema = yup.object().shape({
    animal_name: yup.string().trim().required("You must enter the Animal Name"),
    summary: yup.string().trim().required("You must enter the Animal Summary"),
    category: yup.string().trim().required("You must enter the Category"),
  });

  const defaultValues = {
    animal_name: "",
    summary: "",
    category: "SR",
  };

  const { handleSubmit, reset, control, formState, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const changeRecommendationsHandler = (index, event) => {
    let data = recommendations;
    data[index] = event.target.value;
    setRecommendations([...data]);
  };

  const addRecommendationsHandler = () => {
    let data = [...recommendations, ""];
    setRecommendations(data);
  };

  const removeRecommendationsHandler = (index) => {
    let data = [...recommendations];
    data.splice(index, 1);
    setRecommendations(data);
  };

  function uploadanimalImageHandler(e) {
    if (e.target.files[0]) {
      //   let img = new Image();
      //   img.src = URL.createObjectURL(e.target.files[0]);
      //   await img.decode();
      //   let width = img.width;
      //   let height = img.height;
      //   if (width != 500 || height != 500) {
      //     setOpenSnackbar(true);
      //     setAlertMessage("Please select the image with dimentions 1706 x 1229");
      //     setSeverity("error");
      //     let s = document.getElementById("getFile");
      //     s.value = null;
      //     return;
      //   }
      if (e.target.files[0].size > 5e6) {
        setOpenSnackbar(true);
        setAlertMessage("Please upload a file smaller than 5 MB");
        setSeverity("error");
        let s = document.getElementById("getFile");
        s.value = null;
      } else {
        setAnimalImage(e.target.files[0]);
      }
    }
  }

  const addAnimalHandler = (data) => {
    setShowCircularProgress(true);
    const formdata = new FormData();
    const animalsdata = {};
    animalsdata["animal_name"] = data["animal_name"];
    animalsdata["category"] = data["category"];
    animalsdata["summary"] = data["summary"];
    animalsdata["recommendations"] = [...recommendations];
    formdata.append("file", animalImage);
    formdata.append("data", JSON.stringify(animalsdata));
    const headers = {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": "multipart/form-data",
    };
    call(`animals/`, "POST", { headers: headers, body: formdata })
      .then((res) => {
        setShowCircularProgress(false);
        if (res.status == 200) {
          reset(defaultValues);
          setRecommendations([""]);
          let s = document.getElementById("getFile");
          s.value = null;
          setOpenSnackbar(true);
          setAlertMessage("Animal Data Added Sucessfully");
          setSeverity("success");
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else {
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
          setSeverity("error");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setShowCircularProgress(false);
        setOpenSnackbar(true);
        setAlertMessage("Error while adding the Animal Data");
        setSeverity("error");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={`Add Animal Data`} />
      <form onSubmit={handleSubmit((data) => addAnimalHandler(data))}>
        <Card style={{ height: "100%", width: "100%" }}>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                Anilmal name:
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 50 }}
                    size="large"
                    error={!!errors.animal_name}
                    helperText={errors?.animal_name?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-animal_name",
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="animal_name"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FormLabel sx={{ marginBottom: "10px", fontSize: "20px" }}>Category :</FormLabel>
              <Controller
                name="category"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="large"
                    multiple={false}
                    options={categoriesList}
                    value={value}
                    onChange={(event, newValue) => {
                      // typeChangeHandler(newValue);
                      onChange(newValue);
                    }}
                    limitTags={3}
                    renderInput={(params) => (
                      <TextField
                        sx={{ height: 50 }}
                        size="large"
                        error={!!errors.category}
                        helperText={errors?.category?.message}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-type",
                        }}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12} xl={12}>
              <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                Summary:
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 70, width: "100%" }}
                    size="large"
                    multiline
                    rows={2}
                    error={!!errors.summary}
                    helperText={errors?.summary?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-summary",
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="summary"
                control={control}
              />
            </Grid>
            <Grid item xs={12} xl={12}>
              <Grid
                container
                spacing={1}
                border={1}
                boxShadow={"5"}
                mt={2}
                mb={2}
                borderColor={"#49a3f1"}
              >
                <Grid item xs={12} xl={12}>
                  <FormLabel sx={{ marginBottom: "10px", fontSize: "20px" }}>
                    Recommendations:
                  </FormLabel>
                </Grid>
                {recommendations.map((recom, index) => {
                  return (
                    <Grid
                      container
                      spacing={1}
                      border={1}
                      m={2}
                      pb={1}
                      boxShadow={"3"}
                      borderColor={"#0c85ed"}
                    >
                      <Grid item xs={12} sm={12} md={9} lg={10} xl={10} pr={1}>
                        <TextField
                          sx={{ height: 70 }}
                          required
                          name="recommendation"
                          placeholder="Add Text here"
                          multiline
                          rows={2}
                          value={recom}
                          size="large"
                          onChange={(event) => changeRecommendationsHandler(index, event)}
                          inputProps={{
                            autoComplete: "new-recommendation",
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                        {index != 0 && (
                          <MDBox
                            display="flex"
                            flex={1}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <MDButton
                              sx={{
                                marginTop: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "10px",
                                  lg: "10px",
                                  xl: "10px",
                                },
                              }}
                              style={{
                                color: "white",
                                background: "#f6821e",
                                justifyContent: "center",
                                width: "100%",
                                marginRight: "5px",
                              }}
                              onClick={() => {
                                removeRecommendationsHandler(index);
                              }}
                              type="button"
                            >
                              Remove
                            </MDButton>
                          </MDBox>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12} xl={12} mb={2}>
                  <MDBox display="flex" justifyContent="center">
                    <MDButton
                      onClick={addRecommendationsHandler}
                      style={{
                        color: "white",
                        background: "#0AB2D6",
                        marginTop: "5px",
                        display: "flex",
                        marginRight: "5px",
                        justifyContent: "center",
                      }}
                      type="button"
                    >
                      Add More Recommendations
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={12}>
              <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                Animal Image
              </FormLabel>
              <MDBox display="flex" alignItems="flex-start" justifyContent="flex-start">
                <Controller
                  render={({ field }) => (
                    <TextField
                      type="file"
                      id="getFile"
                      required
                      size="large"
                      style={{
                        display: "block",
                        borderRadius: "5px",
                        height: "100%",
                        marginTop: "3px",
                        color: "white",
                        background: "#0AB2D6",
                      }}
                      {...field}
                      onChange={uploadanimalImageHandler}
                      inputProps={{
                        accept: ".png",
                      }}
                      variant="outlined"
                    />
                  )}
                  name="file"
                  control={control}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} xl={12}>
              {showCircularProgress ? (
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    disabled
                    type="button"
                  >
                    <CircularProgress color="white" size={20} />
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    type="submit"
                  >
                    Add Animal Data
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </Card>
      </form>

      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

export default AddAnimalSummary;
