// @mui material components
import Grid from "@mui/material/Grid";
import { useEffect, useState, useMemo } from "react";

// AWS Authenticator Components
import {
  Authenticator,
  Text,
  Image,
  useTheme,
  View,
  useAuthenticator,
  Heading,
  Button,
  Theme,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./auth.css";
import awsExports from "services/awsService/aws-exports";
import Amplify from "aws-amplify";
import {
  useMaterialUIController,
  setOpenConfigurator,
  setPersona,
  setBeta,
  setProfile,
} from "context";
import { call } from "services/axiosService/core";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate, useLocation, useParams } from "react-router-dom";

// Image
import maxuLogo from "assets/images/auth-logo.png";
import logo from "assets/images/nav-logo.png";

import PageLayout from "examples/LayoutContainers/PageLayout";
import MDBox from "components/MDBox";
import Colors from "assets/theme/base/colors";
import MDTypography from "components/MDTypography";
import Loader from "pages/loader/Loader";
import * as Sentry from "@sentry/browser";
// import { useMaterialUIController } from "context";

Amplify.configure(awsExports);

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.small}>
        <Image alt="MaxU Logo" src={maxuLogo} height={150} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();
    return (
      <View backgroundColor="#49a3f1" textAlign="center" padding={tokens.space.small}>
        <Text color="white">&copy; {new Date().getFullYear()} Sunshine Analytics, LLC.</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign In
        </Heading>
      );
    },
    Footer() {
      const { toSignUp, toResetPassword } = useAuthenticator();
      const navigate = useNavigate();
      return (
        <div>
          <View textAlign="center">
            <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
              Forgot your password?
            </Button>
          </View>
          <View textAlign="center" display="flex" justifyContent="center" alignItems="center">
            <Text>No account?</Text>
            <Button
              fontWeight="normal"
              onClick={() => {
                navigate("/auth/signup");
                toSignUp();
              }}
              size="small"
              variation="link"
            >
              Create Account
            </Button>
          </View>
        </div>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Create Account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      const navigate = useNavigate();

      return (
        <View textAlign="center" display="flex" justifyContent="center" alignItems="center">
          <Text>Have an account?</Text>
          <Button
            fontWeight="normal"
            onClick={() => {
              navigate("/auth/signin");
              toSignIn();
            }}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
};

const formFields = {
  signUp: {
    name: {
      order: 1,
      placeholder: "Name",
      isRequired: true,
    },
    email: {
      order: 2,
      isRequired: true,
    },
    password: {
      order: 3,
      isRequired: true,
    },
    confirm_password: {
      order: 4,
      isRequired: true,
    },
  },
};

export function Auth() {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;
  let query = useQuery();
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, persona } = controller;

  const { route } = useAuthenticator((context) => [context.route]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";
  const { tokens } = useTheme();
  const { initialState } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const referral = query.get("referral");
  const source = query.get("source")
  const signupSource = source ? "PG" : referral && referral.includes('_') ? referral.split('_')[0] + "_api" : null;

  useEffect(() => {
    if (route === "authenticated") {
      if (referral) {
        localStorage.setItem("referral", referral);
      }
      const user_id = user.getUsername();
      const user_email = user.getSignInUserSession().getIdToken().decodePayload().email;
      localStorage.setItem(
        "session",
        JSON.stringify({
          jwtToken: user.getSignInUserSession().getAccessToken().getJwtToken(),
          expire: user.getSignInUserSession().getAccessToken().getExpiration(),
          userName: user_id,
          iss: user.getSignInUserSession().getAccessToken().payload["iss"],
        })
      );
      let user_data;
      setIsLoading(true);
      call(`users/${user_id}`, "GET", { params: { type: "signin", email: user_email } })
        .then((response) => {
          // console.log("response is ", response);
          // show  loading page
          if (response.status == 200) {
            setDetails(response.data);
          } else if (response.status == 404) {
            // user is not present - create the user as Adult
            const data = {
              details: {
                address: {
                  city: null,
                  country: null,
                  state: null,
                  street: null,
                  zip: null,
                },
                persona: null,
                dob: null,
                name: null,
              },
              true_mindsets: source=="true-mindsets",
              email: user_email,
              cognito_user_id: user_id,
              identities: [
                {
                  amazon: { id: "" },
                  apple: { id: "" },
                  email: { id: user_id },
                  facebook: { id: "" },
                  google: { id: "" },
                },
              ],
              profile_image_url: null,
              relationships: { children: [] },
              type: ["Adult"],
              status: "inactive",
              source: signupSource, 
              payment_status: "N/A",
            };
            call(`users`, "POST", { body: data })
              .then((resp) => {
                if (resp.status == 200) {
                  setDetails(resp.data);
                } else if (resp.status == 500) {
                  console.log("Internal Server Error", resp);
                }
              })
              .catch(function (error) {
                Sentry.captureException(error);
                console.log(error);
                setIsLoading(false);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
          Sentry.captureException(error);
          // setIsLoading(false);
        });
    }
  }, [route, navigate, from]);

  const setDetails = (resp) => {
    setProfile(dispatch, resp);
    setBeta(dispatch, resp.beta ? true : false);
    setIsLoading(false);
    const type = resp.type;
    if (type.length == 1) {
      type[0].toLowerCase() === "learner" && setPersona(dispatch, "athlete");
      type[0].toLowerCase() === "adult" && setPersona(dispatch, "guardian");
      type[0].toLowerCase() === "admin" && setPersona(dispatch, "admin");
      type[0].toLowerCase() === "contentmanager" && setPersona(dispatch, "contentManager");
    } else if (type.length == 2) {
      // type will be ["Learner","Adult"] or ["Adult","Parent"]
      type[0].toLowerCase() === "adult" && setPersona(dispatch, "guardian");
      type[0].toLowerCase() === "learner" && setPersona(dispatch, "adultAthlete");
    } else {
      // type will be ["Learner","Adult","Parent"] or ["Learner","Adult","Coach"] or ["Adult","Parent", "Coach"] or ["Learner","Adult","Parent", "Coach"]
      type[0].toLowerCase() === "learner" && setPersona(dispatch, "adultAthlete");
      type[0].toLowerCase() === "adult" && setPersona(dispatch, "guardian");
    }
    if (from == "/") {
      navigate("/dashboard", { replace: true });
    } else {
      navigate(from, { replace: true });
    }
  };

  // const theme: Theme = {
  //   name: "Auth Theme",
  //   tokens: {
  //     components: {
  //       tabs: {
  //         item: {
  //           _active: {
  //             color: {
  //               value: "black",
  //             },
  //             backgroundColor: {
  //               value: "white",
  //             },
  //           },
  //           _focus:{
  //             color: {
  //               value: "black",
  //             },
  //             backgroundColor: {
  //               value: "white",
  //             }
  //           },
  //         },
  //       },
  //     },
  //   },
  // };

  return (
    <PageLayout background={isLoading ? "white" : Colors.authBackground.default}>
      <Grid
        container
        sx={{
          backgroundColor: isLoading ? "white" : Colors.authBackground.default,
        }}
      >
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <MDBox
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="center"
            minHeight="100vh"
          >
            {/* <ThemeProvider theme={theme}> */}
            <Authenticator
              initialState={initialState == "signup" ? "signUp" : "signIn"}
              loginMechanisms={["email"]}
              formFields={formFields}
              components={components}
              socialProviders={["apple", "amazon", "facebook", "google"]}
            />
            {/* </ThemeProvider> */}
            <MDBox display="flex" justifyContent="center">
              {isLoading && <Loader loaderText="Welcome to MaxU" />}
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}
