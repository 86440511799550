import React, { useState, useEffect } from "react";
import { call } from "services/axiosService/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context";
import Refresh from "pages/refresh/Refresh";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AchievementCard from "examples/Cards/ProjectCards/AchievementCard";
import GoldenUser from "assets/images/badges/GoldenUser.jpeg"
import LikedUser from "assets/images/badges/LikedUser.jpeg"
import SilverUser from "assets/images/badges/SilverUser.jpeg"
// import PlatinumUser from "assets/images/badges/PlatinumUser.jpeg"
import TheBeliever from "assets/images/badges/TheBeliever.jpeg"
import TheExplorer from "assets/images/badges/TheExplorer.jpeg"
import ThePhantom from "assets/images/badges/ThePhantom.png"
import TheSuperWatcher from "assets/images/badges/TheSuperWatcher.png"
import TheWatcher from "assets/images/badges/TheWatcher.jpeg"
import badgesData from "./bagesData";
import MDTypography from "components/MDTypography";
import { item } from "examples/Sidenav/styles/sidenavItem";
import * as Sentry from "@sentry/browser";

const logos = {
  GoldenUser,
  LikedUser,
  // PlatinumUser,
  SilverUser,
  TheBeliever,
  TheExplorer,
  ThePhantom,
  TheSuperWatcher,
  TheWatcher
};

const Achievements = ({}) => {
  const [sessionExpired, setSessionExpired] = useState(false);
  const [badges, setBadges] = useState(badgesData);
  const [progress, setProgress] = useState({});
  const [controller] = useMaterialUIController();
  const { profile } = controller;

  useEffect(() => {
    loadProgress();
  }, []);

  const loadProgress = () => {
    call(`users/${profile.id}/achievements/`, "GET", {}).then((res) => {
      if (res.status == 200) {
        setProgress(res.data.progress);
      } else if (res.status == 440) {
        setSessionExpired(true);
      }
    })
    .catch((err) => {
      console.log(err);
      Sentry.captureException(err);
    })
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={`Achievements`} />

      <Grid container spacing={3} flex={1}>
        {badges.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={4}>
              <AchievementCard
                logo={logos[item.logo]}
                name={item.name}
                description={item.description}
                progress={progress[item.key]}
              />
            </Grid>
          );
        })}
      </Grid>

      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

export default Achievements;
