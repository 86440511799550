import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";

// Soft UI Dashboard PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RadarChart from "examples/Charts/RadarChart";
import Breadcrumbs from "examples/Breadcrumbs";

import radarChartData from "layouts/pages/charts/data/radarChartData";
import data from "./Questions";
import { call } from "services/axiosService/core";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useMaterialUIController } from "context";

import Loader from "pages/loader/Loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";

export function Assessment() {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { id: assessment_id } = useParams();
  // const assessment = data.questions;
  const [assessment, setAssessment] = useState([]);
  const [assessmentType, setAssessmentType] = useState();
  const [options, setOptions] = useState([
    "1 - Disagree",
    "2 - Slightly Disagree",
    "3 - Neutral",
    "4 - Slightly Agree",
    "5 - Agree",
  ]);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [answerList, setAnswerList] = useState([]);
  const [activeAssessment, setActiveAssessment] = useState({});
  const [clicked, setClicked] = useState(false);
  const [showInsightsLoader, setShowInsightsLoader] = useState(false);
  const val = (answerList.length / assessment.length) * 100;
  // const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [controller] = useMaterialUIController();
  const { profile } = controller;
  const [sessionExpired, setSessionExpired] = useState(false);

  const answerbtnHandler = (ques, ans) => {
    setClicked(true);
    answerList[ques] = ans;
    setAnswerList(answerList);
    localStorage.setItem("answers" + assessment_id, JSON.stringify(answerList));
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion <= assessment.length) {
      setTimeout(() => {
        setCurrentQuestion(nextQuestion);
        setClicked(false);
      }, 250);
    }
  };

  const handlePreviousQuestion = () => {
    const previousQuestion = currentQuestion - 1;
    if (previousQuestion < assessment.length + 1) {
      setCurrentQuestion(previousQuestion);
    }
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion <= assessment.length) {
      setCurrentQuestion(nextQuestion);
    }
  };

  const submitBtnHandler = () => {
    const answers = localStorage.getItem("answers" + assessment_id);

    const body = {
      answers: answers,
      assigned_date: activeAssessment.assigned_date,
      details: {
        name: activeAssessment.details.name,
      },
      external_id: activeAssessment.external_id,
      id: activeAssessment.id,
      shared_with: activeAssessment.shared_with,
      status: "scoring",
      user_id: activeAssessment.user_id,
    };

    call(`users/${profile.id}/assessments/${assessment_id}`, "PUT", { body: body })
      .then((res) => {
        if (res.status == 200) {
          localStorage.removeItem("answers" + assessment_id);
          // localStorage.removeItem("ActiveAssessment");
          setShowInsightsLoader(true);
          // navigate("/dashboard");
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
  };

  useEffect(() => {
    try {
      const ansValue = JSON.parse(localStorage.getItem("answers" + assessment_id));
      if (!ansValue) {
        console.log("no answers found");
      } else {
        setAnswerList(ansValue);
      }
    } catch (err) {
      console.log(err);
    }
    call(`users/${profile.id}/assessments/${assessment_id}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setActiveAssessment(res.data);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });

    // try {
    //   const newAssessment = JSON.parse(localStorage.getItem("ActiveAssessment"));
    //   if (!newAssessment) {
    //     console.log("no assessment found");
    //   } else {
    //     setActiveAssessment(newAssessment);
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  }, []);

  useEffect(() => {
    if (profile.id && assessment_id) {
      call(`users/${profile.id}/assessments/${assessment_id}/questions`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            setAssessment(res.data.questions);
            if (res.data.assessment_type == "SEARS") {
              setAssessmentType("SEARS");
              setOptions(["1 - Never", "2 - Sometimes", "3 - Often ", "4 - Always"]);
            } else {
              setAssessmentType("NEO");
            }
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err, "err");
        });
    }
  }, []);

  // useEffect(() => {

  // }, [])

  const putHandler = () => {
    setCurrentQuestion(answerList.length);
    if (activeAssessment.status == "not_started") {
      const body = {
        assigned_date: activeAssessment.assigned_date,
        details: {
          name: activeAssessment.details.name,
        },
        external_id: activeAssessment.external_id,
        shared_with: activeAssessment.shared_with,
        status: "in_progress",
      };

      call(`/users/${profile.id}/assessments/${assessment_id}`, "PUT", { body })
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadcrumbs={
          <Breadcrumbs
            icon="home"
            title={`MaxU Annual Player Assessment`}
            route={["Assessments"]}
            light={false}
          />
        }
      />
      {showInsightsLoader && (
        <PreparingInsightsDialog
          assessmentId={assessment_id}
          userId={profile.id}
          showInsightsLoader={showInsightsLoader}
          setShowInsightsLoader={setShowInsightsLoader}
          setSessionExpired={setSessionExpired}
        />
      )}

      <MDBox my={3}>
        <Card>
          <MDBox
            bgColor="white"
            variant="gradient"
            borderRadius="xl"
            sx={{ background: ({ palette: { background } }) => false && background.card }}
          >
            {currentQuestion < 0 && assessmentType == "NEO" && (
              <>
                <MDBox pt={3} px={3}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Thanks for participating in the MaxU yearly assessment! We want to know how you
                    feel about different things. You'll see statements, and you need to pick the
                    number that shows how much you agree or disagree:
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    1: I Strongly Disagree
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    2: I Disagree
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    3: I'm not sure
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    4: I agree
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    5: I strongly agree
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Remember, there are no right or wrong answers. We just want your honest opinion.
                    Please try to answer all the questions. If you don't understand or need help,
                    ask an adult.
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    It'll take no more than 5 minutes to finish the assessment.
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Thank you for taking the first step to optimizing your mindset and maximizing
                    your performance! Let's get started.
                  </MDTypography>
                </MDBox>
                <MDBox textAlign={"center"} p={2}>
                  {/* <MDButton color="primary" onClick={() => setCurrentQuestion(answerList.length)}> */}
                  <MDButton color="primary" onClick={putHandler}>
                    {activeAssessment.status == "not_started"
                      ? "Begin Assessment"
                      : "Resume Assessment"}
                  </MDButton>
                </MDBox>
              </>
            )}
            {currentQuestion < 0 && assessmentType == "SEARS" && (
              <>
                <MDBox pt={3} px={3}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Thanks for participating in the MaxU yearly assessment! We want to know how you
                    feel about different things. You'll see statements, and you need to pick the
                    number that shows how much you agree or disagree:
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    1: Never
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    2: Sometimes
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    3: Often
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    4: Always
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Remember, there are no right or wrong answers. We just want your honest opinion.
                    Please try to answer all the questions. If you don't understand or need help,
                    ask an adult.
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    It'll take no more than 5 minutes to finish the assessment.
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Thank you for taking the first step to optimizing your mindset and maximizing
                    your performance! Let's get started.
                  </MDTypography>
                </MDBox>
                <MDBox textAlign={"center"} p={2}>
                  {/* <MDButton color="primary" onClick={() => setCurrentQuestion(answerList.length)}> */}
                  <MDButton color="primary" onClick={putHandler}>
                    {activeAssessment.status == "not_started"
                      ? "Begin Assessment"
                      : "Resume Assessment"}
                  </MDButton>
                </MDBox>
              </>
            )}

            {currentQuestion > -1 && currentQuestion <= assessment.length && (
              <>
                <MDBox pt={4} pb={2} px={3} textAlign="center">
                  <MDBox px={2} mb={5} sx={{ width: 1 }}>
                    <MDProgress value={Math.round(val)} label={Math.round(val)} />
                  </MDBox>

                  {currentQuestion < assessment.length && (
                    <>
                      <MDTypography variant="h4" fontWeight="medium" color="dark" mb={2}>
                        {assessment[currentQuestion]}
                      </MDTypography>
                    </>
                  )}
                </MDBox>

                {currentQuestion < assessment.length && (
                  <>
                    <MDBox textAlign="center" p={2}>
                      <ButtonGroup
                        variant="contained"
                        size="large"
                        orientation={`${matches ? `horizontal` : `vertical`}`}
                        style={{ boxShadow: "none" }}
                      >
                        {options.map((ans, index) => {
                          return (
                            <MDButton
                              key={index}
                              size="medium"
                              color={
                                (answerList[currentQuestion] === index + 1
                                  ? "success"
                                  : "greyMedium") || (clicked ? "success" : "greyMedium")
                              }
                              onClick={() => {
                                answerbtnHandler(currentQuestion, index + 1);
                              }}
                            >
                              <MDTypography variant="p" fontWeight="medium" color="dark">
                                {ans}
                              </MDTypography>
                            </MDButton>
                          );
                        })}
                      </ButtonGroup>
                    </MDBox>
                    <MDBox px={5} py={2} display="flex" justifyContent="space-between">
                      <MDButton color="primary" onClick={handlePreviousQuestion}>
                        Previous
                      </MDButton>
                      <MDButton
                        color={answerList.length <= currentQuestion ? "secondary" : "primary"}
                        disabled={answerList.length <= currentQuestion}
                        onClick={() => handleNextQuestion()}
                      >
                        Next
                      </MDButton>
                    </MDBox>
                  </>
                )}
              </>
            )}
            {currentQuestion === assessment.length && (
              <>
                <MDBox px={3} textAlign="center">
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Thank you for completing the assessment!
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Please click to submit your answers. In a moment, you will receive valuable
                    insights, detailed content, and actionable steps to help you understand yourself
                    better and optimize your performance.
                  </MDTypography>
                  {/* <MDTypography variant="h6" fontWeight="medium" color="dark">
                    You will be notified once your results are available to view.
                  </MDTypography> */}
                </MDBox>
                <MDBox textAlign={"center"} p={3}>
                  <MDButton color="primary" onClick={submitBtnHandler}>
                    Submit Assessment
                  </MDButton>
                </MDBox>
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
}

const PreparingInsightsDialog = ({
  assessmentId,
  userId,
  setShowInsightsLoader,
  showInsightsLoader,
  setSessionExpired,
}) => {
  const navigate = useNavigate();
  const checkInsights = () => {
    call(`users/${userId}/assessments/${assessmentId}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          const resData = res.data;
          // If Insights not present in assessment data again check after 10 seconds
          if (resData.status == "ready") {
            clearTimeout(myTimeout);
            clearInterval(myInterval);
            navigate(`/insight/${assessmentId}/${userId}`);
          }
        } else if (res.status == 440) {
          setShowInsightsLoader(false);
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
  };

  // Check Insights are ready or not for every 10 seconds
  let myInterval = setInterval(checkInsights, 10000);

  // After 60 seconds stop checking for insights
  let myTimeout = setTimeout(() => {
    setShowInsightsLoader(false);
    clearInterval(myInterval);
    navigate(`/insight/${assessmentId}/${userId}`);
  }, 60000);

  return (
    <Dialog open={showInsightsLoader} fullScreen>
      <DialogContent>
        <Loader loaderText="Scoring your assessment and preparing your MaxU Insights. This process typically takes under a minute." />
      </DialogContent>
    </Dialog>
  );
};
