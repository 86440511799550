const faqData = [
    {
        question: "What is MaxU?",
        answer: ["MaxU exists to help connect a youth athlete with their optimal self by providing kids, as well as parents, guardians, and coaches, a safe and secure platform to explore insights utilizing proprietary technology and testing.", "These insights will empower the youth athlete to take direct action for an athlete to drive awareness, perform better under stress, overcome adversity, and create a positive, championship mindset. "]
    },
    {
        question: "How do I use MaxU?",
        answer: ["First you register - if you're reading this, you're already 1 step ahead!", "Once registered, setup your profile","Add a child and subscribe", "The child will almost immediately receive an assessment", "This quick assessment can then be taken and insights coming from our playbook tailored to your child will be nearly immediately provided", "These insights can securely be shared with other parents or coaches"]
    },
    {
        question: "What is the age range of children who should use MaxU?",
        answer: ["The best results will be for children ages 12-18"]
    },
    {
        question: "I'm an Adult - how do I add a child to MaxU?",
        answer: ["From your profile, click “add child”, enter some basic information including an email address, and the invitation will be sent.  Once you pay for the first year subscription, the child will have access to the assessment."]
    },
    {
        question: "What do I do if my child has no email address?",
        answer: ["We require an individual email address per account on MaxU.  There are a few things that you can do to help if your child has no email address.  The first would be to create one.  There are many email servers, such as gmail, that are absolutely free to use.  If you do have a gmail account, you can always use an email address such as firstname.lastname+maxu@gmail.com as well.  The +maxu.com will register in the MaxU platform as a different email address, but all email traffic will go to your inbox."]


    },
    {
        question: "How to I complete registration so I can take the assessment?",
        answer: [" Click on “Profile” to go to your profile page. From there, click the payment icon and enter your payment information. Your assessment will be ready at that point."]
    },
    {
        question: "How do I take the assessment",
        answer:["Your assessment will be on the link “My Learner Dashboard"]
    },
    {
        question: "What is the other ‘dashboard’?",
        answer:["This dashboard is where you will find insights and information for either children that you’ve added or that you’ve been invited to view."]
    },
    {
        question: "How do I access an assessment for MaxU?",
        answer: ["Once invited by your parent or guardian, follow the link in your email to create an account and then take the assessment."]
    },
    {
        question: "How long does an assessment take?",
        answer: ["An assessment should generally take approximately 5 minutes and no more than 10 minutes."]
    },
    {
        question: "What happens after an assessment is taken?",
        answer: ["Our playbook algorithm will securely crunch the results and email both the parent and the child that insights are ready.  Both will be able to see the insights within a matter of seconds from assessment completion"]
    },
    {
        question: "What are insights?",
        answer: ["Insights are pieces of information that talk through the psychometric make-up of the child.  They give tips and tricks to maximize the individual based on their current makeup."]
    },
    {
        question: "What types of content should I expect?",
        answer: ["You should expect some worksheets, short videos, one-pagers, and other short form content to aid and help with maximizing the child’s mindset."]
    },
    {
        question: "Who developed the content?",
        answer: ["All content was developed by a team of licensed psychologists."]
    },
    {
        question: "Who can see results?",
        answer: ["Specific results and actual test outputs can be seen by nobody.  Insights and content can be viewed by the primary parent, the child, and any other parents or coaches that the primary parent shares with.  The primary parent will always have the option to revoke access to any other adult or coach."]
    },
    {
        question: "Why would I share the results with coaches?",
        answer: ["Coaches are there to help your child get better.  There is more to a game than the physical aspects.  If you feel comfortable, you may share the insights with coaches so the coach can better motivate your child.  If you do not desire to share the insights, you do not need to as you are in charge as the parent."]
    },
    {
        question: "If I’d like to share information with my child’s doctor, psychologist, or other, how can I securely do this?",
        answer: ["At MaxU, we have ensured security within our platform.  If you use the share insights button and enter the doctor’s email address, they will receive the information as a viewer so they can get the best information possible."]

    },
    {
        question: "Is all of this information and data secure?",
        answer: ["Yes!  We use high-level encryption to ensure all information is for you and you alone."]
    },
    {
        question: "What does it cost to use MaxU? ",
        answer: ["A yearly subscription to MaxU is $99.  This provides one assessment yearly and unlimited access to the insights and content."]
    },
    {
        question: "If these questions don’t help, where can I get more help?",
        answer: ["Please email support@maxu.co for more information!"]
    },
]
export default faqData;