import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@mui/material/Autocomplete";

import { call } from "services/axiosService/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import * as Sentry from "@sentry/browser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteAnimalsButton = ({ category, DeleteAnimalsHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete Animals</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            DeleteAnimalsHandler(category);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function AnimalsTable({ animals, loadAnimals, setSessionExpired }) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedAnimalsDetails, setSelectedAnimalsDetails] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [severity, setSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const DeleteAnimalsHandler = (category) => {
    setSelectedCategory(category);
    setOpenDeleteDialog(true);
  };

  const CloseDeleteAnimalsDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedCategory("");
  };

  const CloseViewAnimalsDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "10%" },
      { Header: "Animal", accessor: "animal_name", width: "20%" },
      { Header: "Category", accessor: "category", width: "15%" },
      { Header: "animalURL", accessor: "animal_url", width: "30%" },
      { Header: "Actions", accessor: "actions", width: "25%" },
    ],
    rows: animals,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    id: row.category,
    num: index + 1,
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteAnimalsButton category={row.category} DeleteAnimalsHandler={DeleteAnimalsHandler} />
      </div>
    ),
  }));

  useEffect(() => {
    if (!openDeleteDialog) {
      if (selectedId) {
        const animal = animals.filter((row) => {
          return row.category == selectedId;
        });
        setSelectedAnimalsDetails(animal[0]);

        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);

  return (
    <>
      <DataTable
        table={tableData}
        setSelectedId={setSelectedId}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      {openViewDialog && (
        <ViewAnimalsDataDialog
          openViewDialog={openViewDialog}
          CloseViewAnimalsDialogHandler={CloseViewAnimalsDialogHandler}
          selectedAnimalsDetails={selectedAnimalsDetails}
          loadAnimals={loadAnimals}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openDeleteDialog && (
        <DeleteAnimalsDialog
          openDeleteDialog={openDeleteDialog}
          CloseDeleteAnimalsDialogHandler={CloseDeleteAnimalsDialogHandler}
          selectedCategory={selectedCategory}
          loadAnimals={loadAnimals}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}
      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

const DeleteAnimalsDialog = ({
  openDeleteDialog,
  CloseDeleteAnimalsDialogHandler,
  selectedCategory,
  loadAnimals,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const deleteAnimals = () => {
    call(`animals/${selectedCategory}`, "DELETE", {})
      .then((res) => {
        if (res.status == 200) {
          setOpenSnackbar(true);
          setAlertMessage("Animal Data Deleted Sucessfully");
          setSeverity("success");
          CloseDeleteAnimalsDialogHandler();
          loadAnimals();
        } else if (res.status == 440) {
          setSessionExpired(true);
          CloseDeleteAnimalsDialogHandler();
        } else {
          setOpenSnackbar(true);
          setAlertMessage("Error while Deleting Animal Data");
          setSeverity("error");
        }
      })
      .catch((err) => {
        CloseDeleteAnimalsDialogHandler();
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={openDeleteDialog} onClose={CloseDeleteAnimalsDialogHandler} maxWidth={"md"}>
      <DialogTitle> Delete Animals </DialogTitle>
      <DialogContent>Are you sure you want to Delete this Animal Data ?</DialogContent>

      <DialogActions>
        <Button onClick={deleteAnimals} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={CloseDeleteAnimalsDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ViewAnimalsDataDialog = ({
  openViewDialog,
  CloseViewAnimalsDialogHandler,
  selectedAnimalsDetails,
  loadAnimals,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const [details, setDetails] = useState(selectedAnimalsDetails);
  const [animalImage, setAnimalImage] = useState(null);
  const [animalImageURL, setAnimalImageURL] = useState(details.animal_url);
  const [recommendations, setRecommendations] = useState(details.recommendations);
  const [showCircularProgress, setShowCircularProgress] = useState(false);

  const schema = yup.object().shape({
    animal_name: yup.string().trim().required("You must enter the Animal Name"),
    summary: yup.string().trim().required("You must enter the Animals Summary"),
    category: yup.string().trim().required("You must enter the Category"),
  });

  const defaultValues = {
    animal_name: details.animal_name,
    category: details.category,
    summary: details.summary,
    animal_url: details.animal_url,
  };

  const { handleSubmit, reset, control, formState, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const changeRecommendationsHandler = (index, event) => {
    let data = recommendations;
    data[index] = event.target.value;
    setRecommendations([...data]);
  };

  const addRecommendationsHandler = () => {
    let data = [...recommendations, ""];
    setRecommendations(data);
  };

  const removeRecommendationsHandler = (index) => {
    let data = [...recommendations];
    data.splice(index, 1);
    setRecommendations(data);
  };

  const ResetAnimalsHandler = () => {
    reset();
    setRecommendations(details.recommendations);
    setAnimalImageURL(details.animal_url);
    let s = document.getElementById("getFile");
    s.value = null;
  };

  function uploadanimalImageHandler(e) {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5e6) {
        setOpenSnackbar(true);
        setAlertMessage("Please upload a file smaller than 5 MB");
        setSeverity("error");
        let s = document.getElementById("getFile");
        s.value = null;
      } else {
        setAnimalImageURL(null);
        setAnimalImage(e.target.files[0]);
      }
    }
  }

  const updateAnimalsHandler = (data) => {
    setShowCircularProgress(true);
    const formdata = new FormData();
    const animalsdata = {};
    animalsdata["animal_name"] = data["animal_name"];
    animalsdata["category"] = data["category"];
    animalsdata["summary"] = data["summary"];
    animalsdata["animal_url"] = animalImageURL;
    animalsdata["recommendations"] = [...recommendations];
    formdata.append("file", animalImage);
    formdata.append("data", JSON.stringify(animalsdata));
    const headers = {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": "multipart/form-data",
    };
    call(`animals/${details.category}/`, "PUT", { headers: headers, body: formdata })
      .then((res) => {
        setShowCircularProgress(false);
        if (res.status == 200) {
          loadAnimals();
          setOpenSnackbar(true);
          setAlertMessage("Animal Data Updated Sucessfully");
          setSeverity("success");
          CloseViewAnimalsDialogHandler();
        } else if (res.status == 440) {
          setSessionExpired(true);
          CloseViewAnimalsDialogHandler();
        } else {
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
          setSeverity("error");
          CloseViewAnimalsDialogHandler();
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setShowCircularProgress(false);
        setOpenSnackbar(true);
        setAlertMessage("Error while updating the Animals Data");
        setSeverity("error");
        CloseViewAnimalsDialogHandler();
      });
  };

  return (
    <Dialog
      open={openViewDialog}
      onClose={CloseViewAnimalsDialogHandler}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle> View / Edit Animals Details </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit((data) => updateAnimalsHandler(data))}>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                Anilmal name:
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 50 }}
                    size="large"
                    error={!!errors.animal_name}
                    helperText={errors?.animal_name?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-animal_name",
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="animal_name"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                Category:
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 50 }}
                    size="large"
                    disabled
                    error={!!errors.category}
                    helperText={errors?.catgory?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-category",
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="category"
                control={control}
              />
            </Grid>
            {animalImageURL && (
              <Grid item xs={12} lg={12} xl={12}>
                <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                  Animal Image URL:
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ height: 50, width: "100%" }}
                      size="large"
                      disabled
                      {...field}
                      inputProps={{
                        autoComplete: "new-url",
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  name="animal_url"
                  control={control}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={12} xl={12}>
              <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                Summary:
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 70, width: "100%" }}
                    size="large"
                    multiline
                    rows={2}
                    error={!!errors.summary}
                    helperText={errors?.summary?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-summary",
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="summary"
                control={control}
              />
            </Grid>
            <Grid item xs={12} xl={12}>
              <Grid
                container
                spacing={1}
                border={1}
                boxShadow={"5"}
                mt={2}
                mb={2}
                borderColor={"#49a3f1"}
              >
                <Grid item xs={12} xl={12}>
                  <FormLabel sx={{ marginBottom: "10px", fontSize: "20px" }}>
                    Recommendations:
                  </FormLabel>
                </Grid>
                {recommendations.map((recom, index) => {
                  return (
                    <Grid
                      container
                      spacing={1}
                      border={1}
                      m={2}
                      pb={1}
                      boxShadow={"3"}
                      borderColor={"#0c85ed"}
                    >
                      <Grid item xs={12} sm={12} md={9} lg={10} xl={10} pr={1}>
                        <TextField
                          sx={{ height: 70 }}
                          required
                          name="recommendation"
                          placeholder="Add Text here"
                          multiline
                          rows={2}
                          value={recom}
                          size="large"
                          onChange={(event) => changeRecommendationsHandler(index, event)}
                          inputProps={{
                            autoComplete: "new-recommendation",
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                        {index != 0 && (
                          <MDBox
                            display="flex"
                            flex={1}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <MDButton
                              sx={{
                                marginTop: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "10px",
                                  lg: "10px",
                                  xl: "10px",
                                },
                              }}
                              style={{
                                color: "white",
                                background: "#f6821e",
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                marginRight: "5px",
                              }}
                              onClick={() => {
                                removeRecommendationsHandler(index);
                              }}
                              type="button"
                            >
                              Remove
                            </MDButton>
                          </MDBox>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12} xl={12} mb={2}>
                  <MDBox display="flex" justifyContent="center">
                    <MDButton
                      onClick={addRecommendationsHandler}
                      style={{
                        color: "white",
                        background: "#0AB2D6",
                        marginTop: "5px",
                        display: "flex",
                        marginRight: "5px",
                        justifyContent: "center",
                      }}
                      type="button"
                    >
                      Add More Recommendations
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} xl={12}>
              <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                Upload New Animal Image
              </FormLabel>
              <MDBox display="flex" alignItems="flex-start" justifyContent="flex-start">
                <Controller
                  render={({ field }) => (
                    <TextField
                      type="file"
                      id="getFile"
                      required={animalImageURL == null ? true : false}
                      size="large"
                      style={{
                        display: "block",
                        borderRadius: "5px",
                        height: "100%",
                        marginTop: "3px",
                        color: "white",
                        background: "#0AB2D6",
                      }}
                      {...field}
                      onChange={uploadanimalImageHandler}
                      inputProps={{
                        accept: ".png",
                      }}
                      variant="outlined"
                    />
                  )}
                  name="file"
                  control={control}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} xl={12}>
              {showCircularProgress ? (
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    disabled
                    type="button"
                  >
                    <CircularProgress color="white" size={20} />
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    type="submit"
                  >
                    Update Animals Data
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={ResetAnimalsHandler} style={{ color: "white", background: "#0AB2D6" }}>
          Reset
        </Button>
        <Button
          onClick={CloseViewAnimalsDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
