import React, { useEffect, useState } from "react";
import SubscriptionModal from "./SubscriptionModal";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { call } from "services/axiosService/core";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Payment from "./payment";
import Coupon from "./Coupon";
import { setProfile } from "context";
import TermsAndConditionsPDF from "../../assets/pdf/Terms&Conditions.pdf";
import PaymentTermsPDF from "../../assets/pdf/Payment_Terms.pdf";
import { useNavigate } from "react-router-dom";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";

const EditChildrenButton = ({ row, handleOpenEditChildModal }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Edit</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon fontSize="medium" color="info" onClick={() => handleOpenEditChildModal(row)}>
          edit
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const DeleteChildrenButton = ({ row, handleOpenDeleteModal }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon fontSize="medium" color="error" onClick={() => handleOpenDeleteModal(row)}>
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const PaymentButton = ({ row, handleOpenPaymentModal }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Payment</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon fontSize="medium" color="info" onClick={() => handleOpenPaymentModal(row)}>
          payment
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChildrenTable = ({
  childrenDetails,
  getChildrens,
  loader,
  setSessionExpired,
  openAddChild,
  openPayment,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const [children, setchildren] = useState(childrenDetails);
  const [childForPayment, setChildForPayment] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("maxu_yearly");
  const [openAddChildModal, setOpenAddChildModal] = useState(openAddChild);
  const [openAddAthleteModal, setOpenAddAthleteModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openInstructionModal, setOpenInstructionModal] = useState(false);
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false);
  const [openChildSummaryModal, setOpenChildSummaryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteChildId, setDeleteChildId] = useState();
  const [editChildData, setEditChildData] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [pageNumber, SetPageNumber] = useState(0);
  const [severity, setSeverity] = useState("info");
  const { profile, persona } = controller;
  const navigate = useNavigate();
  const handleOpenAddChildModal = () => setOpenAddChildModal(true);
  const handleOpenAddAthleteModal = () => setOpenAddAthleteModal(true);
  const handleCloseAddChildModal = () => setOpenAddChildModal(false);
  const handleCloseAddAthleteModal = () => setOpenAddAthleteModal(false);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleCloseChildSummaryModal = () => setOpenChildSummaryModal(false);
  const handleClosePaymentSuccessModal = () => {
    if (profile.id == childForPayment.id) {
      navigate("/mylearnerdashboard", { state: true });
    } else {
      setOpenInstructionModal(true);
      setOpenPaymentSuccessModal(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseInstructionModal = () => {
    setOpenInstructionModal(false);
    // setOpenPaymentModal(true);
  };

  const handleCloseSubscriptionModal = (sub) => {
    setSubscriptionType(sub);
    setOpenSubscriptionModal(false);
    setOpenPaymentModal(true);
  };

  const handleClosePaymentModal = (payment_status) => {
    setOpenPaymentModal(false);
    if (payment_status == "success") {
      setOpenPaymentSuccessModal(true);
    }
    getChildrens();
  };

  const handleOpenEditChildModal = (child) => {
    setEditChildData(child);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (child) => {
    setDeleteChildId(child.id);
    setOpenDeleteModal(true);
  };

  const handleOpenChildSummaryModal = () => {
    setOpenChildSummaryModal(true);
  };

  // const handleOpenSubscriptionModal = (child) => {
  //   setChildForPayment(child);
  //   setOpenSubscriptionModal(true);
  // };

  const handleOpenInstructionModal = (child) => {
    setOpenAddAthleteModal(false);
    setOpenAddChildModal(false);
    setOpenInstructionModal(true);
    // setChildForPayment(child);
  };

  const handleOpenPaymentModal = (child) => {
    setOpenAddChildModal(false);
    setOpenAddAthleteModal(false);
    if (profile.true_mindsets) {
      setOpenSubscriptionModal(true);
    } else {
      setOpenPaymentModal(true);
    }
    setChildForPayment(child);
  };

  const updateUserProfile = () => {
    call(`users/${profile.id}`, "GET", {
      body: profile,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status == 200) {
          setProfile(dispatch, response.data);
        } else if (response.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err, "err");
      });
  };

  useEffect(() => {
    if (openPayment) {
      handleOpenPaymentModal(profile);
    }
  }, []);

  const childrenData = {
    columns: [
      { Header: "name", accessor: "name", width: "20%" },
      { Header: "relation", accessor: "relation", width: "20%" },
      { Header: "email", accessor: "email", width: "20%" },
      { Header: "gender", accessor: "gender", width: "10%", align: "center" },
      { Header: "dob", accessor: "dob", width: "10%" },
      { Header: "status", accessor: "status", width: "10%" },
      { Header: "actions", accessor: "actions", width: "15%" },
    ],
    rows: childrenDetails,
  };

  childrenData.rows = childrenData.rows.map((row) => ({
    id: row.id,
    name: row.id == profile.id ? row.details.name + " (owner)" : row.details.name,
    relation: row.relation,
    dob: row.details.dob,
    gender: row.details.gender,
    email: row.email,
    status: row.status,
    actions: (
      <div style={{ display: "flex" }}>
        {!row.is_adult && row.id != profile.id && (
          <div style={{ display: "flex" }}>
            <EditChildrenButton row={row} handleOpenEditChildModal={handleOpenEditChildModal} />
            <DeleteChildrenButton row={row} handleOpenDeleteModal={handleOpenDeleteModal} />
          </div>
        )}
        {row.payment_status != "success" && (
          <PaymentButton row={row} handleOpenPaymentModal={handleOpenPaymentModal} />
        )}
      </div>
    ),
  }));
  return (
    <>
      <MDBox mt={1} mb={1}>
        <Card>
          <MDBox p={1} lineHeight={1}>
            <MDTypography variant="h4" fontWeight="medium">
              Learners
            </MDTypography>
          </MDBox>
          <DataTable
            table={childrenData}
            loader={loader}
            pageNumber={pageNumber}
            SetPageNumber={SetPageNumber}
            noData={true}
            clickHandler={handleOpenAddChildModal}
          />
        </Card>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" m={2}>
        <Button
          onClick={handleOpenAddChildModal}
          variant="contained"
          style={{ color: "white", background: "#F6821E" }}
        >
          Add an athlete (I am their parent)
        </Button>
        <Button
          onClick={handleOpenAddAthleteModal}
          variant="contained"
          style={{ color: "white", background: "#F6821E" }}
        >
          Add an athlete (I am their coach)
        </Button>
        {/* <Button
          onClick={handleOpenChildSummaryModal}
          variant="contained"
          style={{ color: "white", background: "#F6821E" }}
        >
          Learners Summary Report
        </Button> */}
      </MDBox>

      {openAddChildModal && (
        <AddChildModal
          openAddChildModal={openAddChildModal}
          handleCloseAddChildModal={handleCloseAddChildModal}
          getChildrens={getChildrens}
          updateUserProfile={updateUserProfile}
          handleOpenPaymentModal={handleOpenPaymentModal}
          handleOpenInstructionModal={handleOpenInstructionModal}
          setOpenAddChildModal={setOpenAddChildModal}
          profile={profile}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openAddAthleteModal && (
        <AddAthleteModal
          openAddAthleteModal={openAddAthleteModal}
          handleCloseAddAthleteModal={handleCloseAddAthleteModal}
          getChildrens={getChildrens}
          updateUserProfile={updateUserProfile}
          handleOpenPaymentModal={handleOpenPaymentModal}
          handleOpenInstructionModal={handleOpenInstructionModal}
          setOpenAddAthleteModal={setOpenAddAthleteModal}
          profile={profile}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openEditModal && (
        <EditChildModal
          openEditModal={openEditModal}
          handleCloseEditModal={handleCloseEditModal}
          editChildData={editChildData}
          getChildrens={getChildrens}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openDeleteModal && (
        <DeleteChildModal
          openDeleteModal={openDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          getChildrens={getChildrens}
          updateUserProfile={updateUserProfile}
          deleteChildId={deleteChildId}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openInstructionModal && (
        <InstructionModal
          openInstructionModal={openInstructionModal}
          handleCloseInstructionModal={handleCloseInstructionModal}
        />
      )}

      {openSubscriptionModal && (
        <MAXUSubscriptionModal
          openSubscriptionModal={openSubscriptionModal}
          handleCloseSubscriptionModal={handleCloseSubscriptionModal}
          subscriptionType={subscriptionType}
          setSubscriptionType={setSubscriptionType}
        />
      )}

      {openPaymentModal && (
        <PaymentModal
          openPaymentModal={openPaymentModal}
          handleClosePaymentModal={handleClosePaymentModal}
          childDetails={childForPayment}
          setSessionExpired={setSessionExpired}
          subscriptionType={subscriptionType}
        />
      )}
      {openPaymentSuccessModal && (
        <PaymentSuccessModal
          openPaymentSuccessModal={openPaymentSuccessModal}
          handleClosePaymentSuccessModal={handleClosePaymentSuccessModal}
        />
      )}
      {openChildSummaryModal && (
        <ChildSummaryModal
          openChildSummaryModal={openChildSummaryModal}
          handleCloseChildSummaryModal={handleCloseChildSummaryModal}
          profile={profile}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      <Snackbar
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const MAXUSubscriptionModal = ({
  openSubscriptionModal,
  handleCloseSubscriptionModal,
  subscriptionType,
  setSubscriptionType,
}) => {
  return (
    <Dialog open={openSubscriptionModal}>
      <DialogContent>
        <SubscriptionModal isOpen={true} onClose={handleCloseSubscriptionModal} />
      </DialogContent>
    </Dialog>
  );
};

const InstructionModal = ({ openInstructionModal, handleCloseInstructionModal }) => {
  return (
    <Dialog open={openInstructionModal}>
      <CloseIcon
        fontSize="medium"
        onClick={() => {
          handleCloseInstructionModal();
        }}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />

      <DialogContent>
        <Typography variant="h6" textAlign="center" mb={2}>
          Thank you for registering your athlete for MaxU. Your athlete will be receiving an email
          with instructions on how to register and complete their assessment. You will be sent
          notifications along their path.
        </Typography>

        <MDBox display="flex" justifyContent="end">
          <Button
            type="button"
            onClick={handleCloseInstructionModal}
            style={{
              color: "white",
              background: "#0AB2D6",
              marginTop: "15px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            Close
          </Button>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
};
const PaymentModal = ({
  openPaymentModal,
  childDetails,
  handleClosePaymentModal,
  setSessionExpired,
  subscriptionType,
}) => {
  const [controller] = useMaterialUIController();
  const { profile } = controller;
  const [amountToProcess, setAmountToProcess] = useState(
    subscriptionType == "true_mindsets_yearly"
      ? 480
      : subscriptionType == "true_mindsets_monthly"
      ? 45
      : 99
  );
  const [proceedToPayment, setProceedtoPayment] = useState(false);
  const [couponUsed, setCouponUsed] = useState(null);

  return (
    <Dialog
      open={openPaymentModal}
      onClose={() => {
        handleClosePaymentModal("pending");
      }}
    >
      <CloseIcon
        fontSize="medium"
        onClick={() => {
          handleClosePaymentModal("pending");
        }}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>
        <Typography variant="h4" textAlign="center" mb={1}>
          MAXU Payment
        </Typography>
        {subscriptionType == "maxu_yearly" ? (
          profile.id == childDetails.id ? (
            <Typography variant="h6" textAlign="center" mb={2}>
              For access to the MaxU subscription, assessment, and powerful insights – there is a $
              {amountToProcess} annual subscription fee.
            </Typography>
          ) : (
            <Typography variant="h6" textAlign="center" mb={2}>
              For access to the MaxU subscription, assessment, and powerful insights – there is a $
              {amountToProcess} annual subscription fee. Thank you for investing in your child!
            </Typography>
          )
        ) : profile.id == childDetails.id ? (
          <Typography variant="h6" textAlign="center" mb={2}>
            For a{" "}
            {subscriptionType == "true_mindsets_monthly"
              ? "yearly subscription paid monthly (paying in full now saves $60)"
              : "yearly"}{" "}
            to True Mindsets and MaxU, giving you an assessment, powerful insights, and world-class
            coaching, there is a ${amountToProcess} subscription fee.
          </Typography>
        ) : (
          <Typography variant="h6" textAlign="center" mb={2}>
            For a{" "}
            {subscriptionType == "true_mindsets_monthly"
              ? "yearly subscription paid monthly (paying in full now saves $60)"
              : "yearly"}{" "}
            subscription to True Mindsets and MaxU, giving you an assessment, powerful insights, and
            world-class coaching, there is a ${amountToProcess} subscription fee. Thank you for
            investing in your athlete!
          </Typography>
        )}
        {/* {profile.id == childDetails.id ? (
          <Typography variant="h6" textAlign="center" mb={2}>
            For access to the MaxU subscription, assessment, and powerful insights – there is a $
            {amountToProcess} {subscriptionType == "monthly" ? "monthly" : "annual"} subscription fee.
          </Typography>
        ) : (
          <Typography variant="h6" textAlign="center" mb={2}>
            For access to the MaxU subscription, assessment, and powerful insights – there is a $
            {amountToProcess}  {subscriptionType == "monthly" ? "monthly" : "annual"} subscription fee. Thank you for investing in your child!
          </Typography>
        )} */}
      </DialogTitle>
      <DialogContent>
        {!proceedToPayment && (
          <Coupon
            setAmountToProcess={setAmountToProcess}
            setProceedtoPayment={setProceedtoPayment}
            handleClosePaymentModal={handleClosePaymentModal}
            childDetails={childDetails}
            setSessionExpired={setSessionExpired}
            setCouponUsed={setCouponUsed}
            subscriptionType={subscriptionType}
          />
        )}
        {proceedToPayment && (
          <Payment
            childDetails={childDetails}
            handleClosePaymentModal={handleClosePaymentModal}
            amountToProcess={amountToProcess}
            setSessionExpired={setSessionExpired}
            couponUsed={couponUsed}
            subscriptionType={subscriptionType}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const AddChildModal = ({
  openAddChildModal,
  handleCloseAddChildModal,
  getChildrens,
  updateUserProfile,
  handleOpenPaymentModal,
  handleOpenInstructionModal,
  setOpenAddChildModal,
  profile,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  // const [addChild, setAddChild] = useState({
  //   name: "",
  //   lastName:"",
  //   email: "",
  //   gender: "Male",
  //   dob: "",
  //   status: "inactive",
  // });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Male");
  const [dob, setDob] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
  const [paymentTermsChecked, setPaymentTermsChecked] = useState(false);

  useEffect(() => {
    let today = new Date();
    // Parents can add childrens above 12  and below 18 only
    // Giving  limit to the date picker acording to that dates only.
    // if today is MAY-15-2023 start date will be MAY-16-2005(below 18) end date will be MAY-15-2011 (above 12 years)

    let end_date = new Date(
      today.getFullYear() - 8,
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getSeconds()
    );
    setEndDate(end_date.toISOString().slice(0, 10));
    let start_date = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate() + 1,
      today.getHours(),
      today.getMinutes(),
      today.getSeconds()
    );
    setStartDate(start_date.toISOString().slice(0, 10));
  }, []);
  // const onChangeAddChildHandler = (e) => {
  //   setAddChild({ ...addChild, [e.target.name]: e.target.value });
  // };

  const addChildSubmitHandler = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const currentDate = new Date();
    const nameValue = firstName + " " + lastName;
    const body = {
      email: email,
      phone: null,
      relationships: {
        parents: [profile.id],
      },
      type: ["Learner"],
      status: "inactive",
      true_mindsets: profile.true_mindsets ? true : false,
      source : profile.source,
      details: {
        name: nameValue,
        dob: dob,
        persona: ["Athlete"],
        gender: gender,
        address: {
          street: null,
          city: null,
          state: null,
          country: null,
          zip: null,
        },
      },
      payment_status: "pending",
      profile_image_url: null,
      identities: [
        {
          google: {
            id: "",
          },
          facebook: {
            id: "",
          },
          apple: {
            id: "",
          },
          amazon: {
            id: "",
          },
          email: {
            id: "",
          },
        },
      ],
    };
    call("users", "POST", {
      body: body,
      params: { type: "children" },
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response) {
          if (response.status == 403 || response.status == 404 || response.status == 400) {
            setSeverity("error");
            setOpenSnackbar(true);
            setOpenAddChildModal(false);
            setAlertMessage(response.data.message);
          } else if (response.status == 200) {
            setSeverity("success");
            setOpenSnackbar(true);
            setAlertMessage("Children added successfully");
            getChildrens();
            updateUserProfile();
            // handleOpenInstructionModal(response.data);
            handleOpenPaymentModal(response.data);
            setIsLoading(false);
          } else if (response.status == 440) {
            setOpenAddChildModal(false);
            setSessionExpired(true);
          } else {
            setSeverity("error");
            setOpenSnackbar(true);
            setAlertMessage("Error while adding the children");
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        setOpenAddChildModal(false);
        setIsLoading(false);
        console.log(err, "err");
      });
  };

  const termsConditionsCheckboxHandler = () => {
    setTermsConditionsChecked(!termsConditionsChecked);
  };

  const paymentTermsCheckboxHandler = () => {
    setPaymentTermsChecked(!paymentTermsChecked);
  };

  return (
    <Dialog open={openAddChildModal} close={handleCloseAddChildModal}>
      <CloseIcon
        fontSize="medium"
        aria-label="close"
        onClick={handleCloseAddChildModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle tabindex="0">
        <Typography variant="h4" textAlign="center" mb={2}>
          Add Children Details
        </Typography>
        <Typography variant="h6" textAlign="center" mb={2}>
          Note: Children 18 or older must initiate signup themselves. Parents can add the childrens
          between 8 to 18 years only in to MaxU platform.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={addChildSubmitHandler}>
          <Typography variant="h6" textAlign="left" mb={2}>
            First Name:
            <MDInput
              value={firstName}
              placeholder="Enter FirstName"
              rows="1"
              name="firstName"
              aria-label="First Name"
              aria-required="true"
              type="text"
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setFirstName(e.target.value);
                }
              }}
              fullWidth
              required
            />
          </Typography>
          <Typography variant="h6" textAlign="left" mb={2}>
            Last Name:
            <MDInput
              value={lastName}
              placeholder="Enter LastName"
              aria-label="Last Name"
              aria-required="true"
              rows="1"
              name="lastName"
              type="text"
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setLastName(e.target.value);
                }
              }}
              fullWidth
              required
            />
          </Typography>
          <Typography variant="h6" textAlign="left" mb={2}>
            Email:
            <MDInput
              value={email}
              placeholder="Enter Email Address"
              aria-label={"Email"}
              aria-required="true"
              rows="1"
              name="email"
              type="email"
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setEmail(e.target.value);
                }
              }}
              fullWidth
              required
            />
          </Typography>
          <Typography variant="h6" textAlign="left" mb={2}>
            Gender:
            <FormControl fullWidth>
              <Select
                style={{ height: "48px" }}
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                aria-label={"Select Gender"}
                name="gender"
              >
                <MenuItem aria-label="Male" value="Male">
                  Male
                </MenuItem>
                <MenuItem aria-label="Female" value="Female">
                  Female
                </MenuItem>
              </Select>
            </FormControl>
          </Typography>
          <Typography variant="h6" mb={1}>
            Birthdate:
            <TextField
              type="date"
              id="dob"
              aria-describedby="my-helper-text"
              name="dob"
              onChange={(e) => setDob(e.target.value)}
              value={dob}
              inputProps={{
                "aria-label": "Enter Child's Date of Birth",
                min: startDate,
                max: endDate,
              }}
              required
              fullWidth
              style={{ display: "block" }}
            />
          </Typography>

          <FormGroup sx={{ marginTop: "10px" }}>
            <MDBox display="flex" direction="row" gap={1}>
              <MDTypography variant="h6" style={{ paddingTop: "10px" }}>
                <Checkbox
                  required
                  inputProps={{ "aria-label": "Terms and Conditions" }}
                  checked={termsConditionsChecked}
                  onChange={termsConditionsCheckboxHandler}
                />
              </MDTypography>
              <MDTypography variant="h6" style={{ paddingTop: "18px" }}>
                I have read and agree to the
                <Typography
                  component="a"
                  fontSize={15}
                  color="#49a3f1"
                  href={"/terms_conditions"}
                  target="_blank"
                  style={{ paddingTop: "15px" }}
                >
                  {" "}
                  Terms and Conditions
                </Typography>
              </MDTypography>
            </MDBox>
            <MDBox display="flex" direction="row" gap={1}>
              <MDTypography style={{ paddingTop: "10px" }}>
                <Checkbox
                  required
                  inputProps={{ "aria-label": "paymentTerms" }}
                  checked={paymentTermsChecked}
                  onChange={paymentTermsCheckboxHandler}
                />
              </MDTypography>
              <MDTypography variant="h6" style={{ paddingTop: "18px" }}>
                I have read and agree to the
                <Typography
                  component="a"
                  fontSize={15}
                  color="#49a3f1"
                  href={"/payment_terms"}
                  target="_blank"
                  style={{ paddingTop: "15px" }}
                >
                  {" "}
                  Payment Terms
                </Typography>
              </MDTypography>
            </MDBox>
          </FormGroup>

          <MDBox display="flex" justifyContent="end">
            <Button
              type="submit"
              style={{
                color: "white",
                background: "#0AB2D6",
                marginTop: "15px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {isLoading ? <CircularProgress color="white" size={20} /> : "Next"}
            </Button>
          </MDBox>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const AddAthleteModal = ({
  openAddAthleteModal,
  handleCloseAddAthleteModal,
  getChildrens,
  updateUserProfile,
  handleOpenPaymentModal,
  handleOpenInstructionModal,
  setOpenAddAthleteModal,
  profile,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Male");
  const [dob, setDob] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
  const [paymentTermsChecked, setPaymentTermsChecked] = useState(false);

  useEffect(() => {
    let today = new Date();
    // Coaches can add Athlete above 12
    // Giving  limit to the date picker acording to that dates only.
    // if today is MAY-15-2023 start date will be MAY-16-2005(below 18) end date will be MAY-15-2011 (above 12 years)
    let end_date = new Date(
      today.getFullYear() - 12,
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getSeconds()
    );
    setEndDate(end_date.toISOString().slice(0, 10));
    // let start_date = new Date(
    //   today.getFullYear() - 18,
    //   today.getMonth(),
    //   today.getDate() + 1,
    //   today.getHours(),
    //   today.getMinutes(),
    //   today.getSeconds()
    // );
    // setStartDate(start_date.toISOString().slice(0, 10));
  }, []);

  const addAthleteSubmitHandler = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const currentDate = new Date();
    const nameValue = firstName + " " + lastName;
    const diff_ms = Date.now() - new Date(dob).getTime();
    const age_dt = new Date(diff_ms);
    const user_age = Math.abs(age_dt.getUTCFullYear() - 1970);
    const body = {
      email: email,
      phone: null,
      relationships:
        user_age < 18
          ? {
              coaches: [profile.id],
            }
          : {
              coaches: [profile.id],
              children: [],
            },
      type: user_age < 18 ? ["Learner"] : ["Learner", "Adult"],
      true_mindsets: profile.true_mindsets ? true : false,
      source : profile.source,
      status: "inactive",
      details: {
        name: nameValue,
        dob: dob,
        persona: ["Athlete"],
        gender: gender,
        address: {
          street: null,
          city: null,
          state: null,
          country: null,
          zip: null,
        },
      },
      payment_status: "pending",
      profile_image_url: null,
      identities: [
        {
          google: {
            id: "",
          },
          facebook: {
            id: "",
          },
          apple: {
            id: "",
          },
          amazon: {
            id: "",
          },
          email: {
            id: "",
          },
        },
      ],
    };
    call("users", "POST", {
      body: body,
      params: { type: "athlete" },
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response) {
          if (response.status == 403 || response.status == 404 || response.status == 400) {
            setSeverity("error");
            setOpenSnackbar(true);
            setOpenAddAthleteModal(false);
            setAlertMessage(response.data.message);
          } else if (response.status == 200) {
            setSeverity("success");
            setOpenSnackbar(true);
            setAlertMessage("Athelete added successfully");
            getChildrens();
            updateUserProfile();
            // handleOpenInstructionModal(response.data);
            handleOpenPaymentModal(response.data);
            setIsLoading(false);
          } else if (response.status == 440) {
            setOpenAddAthleteModal(false);
            setSessionExpired(true);
          } else {
            setSeverity("error");
            setOpenSnackbar(true);
            setAlertMessage("Error while adding the athlete");
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        setOpenAddAthleteModal(false);
        setIsLoading(false);
        console.log(err, "err");
      });
  };

  const termsConditionsCheckboxHandler = () => {
    setTermsConditionsChecked(!termsConditionsChecked);
  };

  const paymentTermsCheckboxHandler = () => {
    setPaymentTermsChecked(!paymentTermsChecked);
  };

  return (
    <Dialog open={openAddAthleteModal} close={handleCloseAddAthleteModal}>
      <CloseIcon
        fontSize="medium"
        onClick={handleCloseAddAthleteModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>
        <Typography variant="h4" textAlign="center" mb={2}>
          Add Athlete Details
        </Typography>
        <Typography variant="h6" textAlign="center" mb={2}>
          Note: Coaches can add the childrens 12+ yers only in to MaxU platform.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={addAthleteSubmitHandler}>
          <Typography variant="h6" textAlign="left" mb={2}>
            First Name:
            <MDInput
              value={firstName}
              rows="1"
              name="firstName"
              type="text"
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setFirstName(e.target.value);
                }
              }}
              fullWidth
              required
            />
          </Typography>
          <Typography variant="h6" textAlign="left" mb={2}>
            Last Name:
            <MDInput
              value={lastName}
              rows="1"
              name="lastName"
              type="text"
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setLastName(e.target.value);
                }
              }}
              fullWidth
              required
            />
          </Typography>
          <Typography variant="h6" textAlign="left" mb={2}>
            Email:
            <MDInput
              value={email}
              placeholder="xyz@email.com"
              rows="1"
              name="email"
              type="email"
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setEmail(e.target.value);
                }
              }}
              multiline
              fullWidth
              required
            />
          </Typography>
          <Typography variant="h6" textAlign="left" mb={2}>
            Gender:
            <FormControl fullWidth>
              <Select
                style={{ height: "48px" }}
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                name="gender"
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
          </Typography>
          <Typography variant="h6" mb={1}>
            Birthdate:
          </Typography>
          <TextField
            type="date"
            name="dob"
            onChange={(e) => setDob(e.target.value)}
            value={dob}
            inputProps={{ min: startDate, max: endDate }}
            // inputProps={{ min: startDate }}
            required
            fullWidth
            style={{ display: "block" }}
          />

          <FormGroup sx={{ marginTop: "10px" }}>
            <MDBox display="flex" direction="row" gap={1}>
              <MDTypography variant="h6" style={{ paddingTop: "10px" }}>
                <Checkbox
                  required
                  checked={termsConditionsChecked}
                  onChange={termsConditionsCheckboxHandler}
                />
              </MDTypography>
              <MDTypography variant="h6" style={{ paddingTop: "18px" }}>
                I have read and agree to the
                <Typography
                  component="a"
                  fontSize={15}
                  color="#49a3f1"
                  href={"/terms_conditions"}
                  target="_blank"
                  style={{ paddingTop: "15px" }}
                >
                  {" "}
                  Terms and Conditions
                </Typography>
              </MDTypography>
            </MDBox>
            <MDBox display="flex" direction="row" gap={1}>
              <MDTypography variant="h6" style={{ paddingTop: "10px" }}>
                <Checkbox
                  required
                  checked={paymentTermsChecked}
                  onChange={paymentTermsCheckboxHandler}
                />
              </MDTypography>
              <MDTypography variant="h6" style={{ paddingTop: "18px" }}>
                I have read and agree to the
                <Typography
                  component="a"
                  fontSize={15}
                  color="#49a3f1"
                  href={"/payment_terms"}
                  target="_blank"
                  style={{ paddingTop: "15px" }}
                >
                  {" "}
                  Payment Terms
                </Typography>
              </MDTypography>
            </MDBox>
          </FormGroup>

          <MDBox display="flex" justifyContent="end">
            <Button
              type="submit"
              style={{
                color: "white",
                background: "#0AB2D6",
                marginTop: "15px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {isLoading ? <CircularProgress color="white" size={20} /> : "Next"}
            </Button>
          </MDBox>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const EditChildModal = ({
  openEditModal,
  handleCloseEditModal,
  editChildData,
  getChildrens,
  setAlertMessage,
  setOpenSnackbar,
  setSeverity,
  setSessionExpired,
}) => {
  const [editChild, setEditChild] = useState({ name: "", gender: "", dob: "" });
  const [firstName, setFirstName] = useState(editChildData.details.name.split(" ")[0]);
  const [lastName, setLastName] = useState(editChildData.details.name.split(" ")[1] ?? null);
  const [gender, setGender] = useState(editChildData.details.gender);
  const [dob, setDob] = useState(editChildData.details.dob);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    let year = 12;
    if (editChildData.relation == "Child") {
      year = 8;
    }
    let today = new Date();
    let end_date = new Date(
      today.getFullYear() - year,
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getSeconds()
    );
    setEndDate(end_date.toISOString().slice(0, 10));
    // setEndDate(today.toISOString().slice(0, 10));
    let start_date = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate() + 1,
      today.getHours(),
      today.getMinutes(),
      today.getSeconds()
    );
    setStartDate(start_date.toISOString().slice(0, 10));
  }, []);

  const editHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    editChildData.details.name = firstName + " " + lastName;
    editChildData.details.gender = gender;
    editChildData.details.dob = dob;

    call(`users/${editChildData.id}`, "PUT", {
      body: editChildData,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status == 200) {
          setSeverity("success");
          setOpenSnackbar(true);
          setAlertMessage("Children details upated successfully");
          setIsLoading(false);
          handleCloseEditModal();
          getChildrens();
        } else if (response.status == 403 || response.status == 404 || response.status == 400) {
          setSeverity("error");
          setOpenSnackbar(true);
          setAlertMessage(response.data.message);
          setIsLoading(false);
          handleCloseEditModal();
          getChildrens();
        } else if (response.status == 440) {
          setIsLoading(false);
          handleCloseEditModal();
          setSessionExpired(true);
        }
      })
      .catch(function (error) {
        Sentry.captureException(error);
        setSeverity("error");
        setOpenSnackbar(true);
        setAlertMessage("Error while updating children details");
        setIsLoading(false);
        handleCloseEditModal();
        console.log(error);
      });
  };
  return (
    <Dialog open={openEditModal} close={handleCloseEditModal}>
      <CloseIcon
        fontSize="medium"
        aria-label="close"
        onClick={handleCloseEditModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle tabindex="0">
        <Typography variant="h4" mb={1}>
          Edit child
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={editHandler}>
          <Typography variant="h6" mb={1}>
            First Name:
            <MDInput
              inputProps={{ "aria-label": "First Name" }}
              value={firstName}
              placeholder="Enter FirstName"
              rows="1"
              name="firstName"
              type="text"
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setFirstName(e.target.value);
                }
              }}
              multiline
              fullWidth
              required
            />
          </Typography>
          <Typography aria-lable="First Name" variant="h6" mb={1}>
            Last Name:
            <MDInput
              inputProps={{ "aria-label": "Last Name" }}
              value={lastName}
              rows="1"
              placeholder="Enter LastName"
              name="lastName"
              type="text"
              onChange={(e) => {
                if (e.nativeEvent.data != " ") {
                  setLastName(e.target.value);
                }
              }}
              multiline
              fullWidth
              required
            />
          </Typography>
          <Typography variant="h6" mb={1}>
            Gender:
            <FormControl fullWidth>
              <Select
                aria-label={"Select Gender"}
                style={{ height: "48px" }}
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                name="gender"
              >
                <MenuItem aria-label={"Male"} value="Male">
                  Male
                </MenuItem>
                <MenuItem aria-label={"Female"} value="Female">
                  Female
                </MenuItem>
              </Select>
            </FormControl>
          </Typography>
          <Typography variant="h6" mb={1}>
            DOB:
          </Typography>
          <TextField
            type="date"
            name="dob"
            fullWidth
            required
            onChange={(e) => {
              setDob(e.target.value);
            }}
            value={dob}
            inputProps={{ min: startDate, max: endDate }}
            style={{ display: "block" }}
          />
          <MDBox display="flex" justifyContent="end">
            <Button
              type="submit"
              style={{
                marginTop: "15px",
                color: "white",
                background: "#0AB2D6",
              }}
            >
              {isLoading ? <CircularProgress color="white" size={30} /> : " Save Changes"}
            </Button>
          </MDBox>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const DeleteChildModal = ({
  openDeleteModal,
  handleCloseDeleteModal,
  getChildrens,
  updateUserProfile,
  deleteChildId,
  setSessionExpired,
}) => {
  const deleteHandler = () => {
    call(`users/${deleteChildId}`, "DELETE", { params: { type: "children" } })
      .then((response) => {
        if (response.status == 200) {
          getChildrens();
          updateUserProfile();
          handleCloseDeleteModal();
        } else if (response.status == 440) {
          handleCloseDeleteModal();
          setSessionExpired(true);
        }
      })
      .catch(function (error) {
        Sentry.captureException(error);
        handleCloseDeleteModal();
        console.log(error);
      });
  };
  return (
    <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
      <CloseIcon
        fontSize="medium"
        onClick={handleCloseDeleteModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>
        <Typography variant="h4" mb={1}>
          Remove child
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="string" mb={1}>
          Are you sure you want to remove this children profile?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => deleteHandler()}
          style={{
            marginTop: "15px",
            color: "white",
            background: "red",
          }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PaymentSuccessModal = ({ openPaymentSuccessModal, handleClosePaymentSuccessModal }) => {
  return (
    <Dialog open={openPaymentSuccessModal} onClose={handleClosePaymentSuccessModal}>
      <CloseIcon
        fontSize="medium"
        onClick={handleClosePaymentSuccessModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogContent>
        <Typography variant="h4" ma={1}>
          Thank you for your payment!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClosePaymentSuccessModal}
          style={{
            marginTop: "15px",
            color: "white",
            background: "#0AB2D6",
          }}
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ChildSummaryModal = ({
  openChildSummaryModal,
  handleCloseChildSummaryModal,
  profile,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const [activity, setActivity] = useState("weekly");
  const [isLoading, setIsLoading] = useState(false);
  const data = profile;
  profile.learner_activity_email = activity;
  const activityHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    call(`users/${profile.id}`, "PUT", {
      body: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status == 200) {
          setIsLoading(false);
          setSeverity("success");
          setOpenSnackbar(true);
          setAlertMessage("Subscription added successfully");
          handleCloseChildSummaryModal();
        } else if (response.status == 403 || response.status == 404 || response.status == 400) {
          setIsLoading(false);
          setSeverity("error");
          setOpenSnackbar(true);
          setAlertMessage("Error while ading the subscription");
          handleCloseChildSummaryModal();
        } else if (response.status == 440) {
          setIsLoading(false);
          setSessionExpired(true);
          handleCloseChildSummaryModal();
        }
      })
      .catch(function (error) {
        Sentry.captureException(error);
        setIsLoading(false);
        setSeverity("error");
        setOpenSnackbar(true);
        setAlertMessage("Error while ading the subscription");
        handleCloseChildSummaryModal();
      });
  };
  return (
    <Dialog open={openChildSummaryModal} onClose={handleCloseChildSummaryModal}>
      <CloseIcon
        fontSize="medium"
        onClick={handleCloseChildSummaryModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>
        <Typography variant="h4" mb={1}>
          Learners Actvity Summary
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={activityHandler}>
          <Typography variant="text" ma={1}>
            How often would you like to receive your learners' activity summary:
          </Typography>
          <FormControl fullWidth>
            <Select
              style={{ height: "48px" }}
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              name="activity"
            >
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="biweekly">Biweekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="never">Never</MenuItem>
            </Select>
          </FormControl>

          <MDBox display="flex" justifyContent="end">
            <Button
              type="submit"
              style={{
                color: "white",
                background: "#0AB2D6",
                marginTop: "15px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {isLoading ? <CircularProgress color="white" size={20} /> : "Submit"}
            </Button>
          </MDBox>
        </form>
      </DialogContent>
    </Dialog>
  );
};

ChildrenTable.defaultProps = {
  childrenDetails: [],
  getChildrens: () => {},
};

ChildrenTable.propTypes = {
  childrenDetails: PropTypes.array,
  getChildrens: PropTypes.func,
};

export default ChildrenTable;
